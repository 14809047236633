import React from "react";
import './technological.scss'

// 图片
import PicP from '../../assets/images/kjgx-Pic/kjgx-contente.png';
import Mpic from '../../assets/images/kjgx-Pic/sy-bg-jsgx1-2.png';
import {withRouter} from 'react-router';

class Technological extends React.Component{
    constructor(props){
        super(props);
        this.state = {}
    }
    toStudiosWrap = () => {
        this.props.history.push('/StudiosWrap', '2')
    }
    render() {
        return(
            <div className='MoviesBox' onClick={this.toStudiosWrap}>
                <div className="MoviesBox-P">
                    <img src={PicP} alt=''/>
                </div>
                <div className="MoviesBox-M">
                    <img src={Mpic} alt=''/>
                </div>
            </div>
        );
    }
}

export default withRouter(Technological);
