
import "./polyfill";
import "core-js/es";
import 'core-js'
import "mutation-observer";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串  
var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器  
var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;

var browser = {
  versions: function () {
    var u = navigator.userAgent;
    return {
      trident: u.indexOf('Trident') > -1, //IE内核
    };
  }()
}
if (browser.versions.trident && !isIE11) {
  window.location.href = '/updateBrowser.html';
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}



// If you want your FilmBanner to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
