import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { request } from '../../../../utils/Axios';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import './SwiperWrap.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

let API_HOST_PRD_IMG = ''
let MEDIA_CDN_URL = ''

class FilmSwiper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataListV: [],
            currentImg: '',
        };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    componentDidMount() {
        request({
            url: '/party/api/getPropertyGetByPropertyId?resourceId=DOMAIN_IMG_SSL&propertyId=DOMAIN_IMG_SSL',
        })
            .then((res) => {
                API_HOST_PRD_IMG = res.systemPropertyValue
                request({
                    url: '/party/api/getPropertyGetByPropertyId?resourceId=MEDIA_CDN_URL&propertyId=MEDIA_CDN_URL',
                })
                    .then((res) => {
                        MEDIA_CDN_URL = res.systemPropertyValue
                        console.log(MEDIA_CDN_URL, '视频url')
                    });
            });
    }
    //设置当前图片
    setCurImg = (data) => {
        this.setState({
            currentImg: data
        })
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {
        let settings = {};
        if (window.screen.width < 450) {
            settings = {
                dots: false,
                infinite: false,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 3,
                center: false
            };
        } else {
            settings = {
                dots: false,
                infinite: false,
                speed: 500,
                slidesToShow: 5,
                slidesToScroll: 5,
                center: false
            };
        }
        let DataListV = this.props.data
        const { currentImg } = this.state
        let curImgData = currentImg ? currentImg : this.props.data.length > 0 ? this.props.data[0] : ''

        return (
            <div>
                {
                    DataListV.length > 0 ? <div className="film-swiper">
                        <div className="bigimg m-hied">
                            {
                                curImgData.substring(curImgData.lastIndexOf("."), curImgData.length) == '.mp4' ?
                                    <video src={MEDIA_CDN_URL + curImgData} className="img" controls></video> :
                                    <img src={API_HOST_PRD_IMG + curImgData} alt='' className="img" />
                            }
                        </div>
                        <div className="swiper-wrap-box" >
                            <Slider ref={c => (this.slider = c)} {...settings}>
                                {
                                    _.map(DataListV, (item, index) => {
                                        return (
                                            <div className="dy-img-box" key={index} onClick={() => this.setCurImg(item)}>
                                                {

                                                    item.substring(item.lastIndexOf("."), item.length) == '.mp4' ?
                                                        <video src={MEDIA_CDN_URL + item} className="img" controls></video> :
                                                        <img
                                                            src={API_HOST_PRD_IMG + item}
                                                            alt=''
                                                            className="img"
                                                        />
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </Slider>

                            <span className="slider-btn left-slider-btn" onClick={this.previous}><LeftOutlined style={{ color: '#fff' }} /></span>
                            <span className="slider-btn right-slider-btn" onClick={this.next}><RightOutlined style={{ color: '#fff' }} /></span>
                        </div>
                    </div> : ''
                }
            </div>

        );
    }
}

export default FilmSwiper;
