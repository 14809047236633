import React from 'react';
import _ from 'lodash';
import { Carousel } from 'antd';
import { request } from '../../utils/Axios'
import { withRouter } from 'react-router';
import { RightOutlined } from '@ant-design/icons';
import 'swiper/dist/css/swiper.min.css';
import './notice.scss';
import Axios from 'axios';
class Notice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: []
        };
    }
    componentDidMount() {
        // 取公告数据
        this.getData();
        setTimeout(() => {
            this.getData();
        }, 1)
    }

    getData = () => {
        // Axios.get( '/content/content/notice?contentTypeId=8')
        //     .then((res) => {
        //         if (res.status === 200) {
        //             this.setState({
        //                 list: res.data.data
        //             });
        //         }
        //     })
        request({
            url: '/content/api/announcement/query?channel=HOMEPAGE&thatcd='
        })
            .then((res) => {
                if (res.code === 200) {
                    this.setState({
                        list: res.data
                    });
                }
            })
    };
    toHelpWrap = () => {
        this.props.history.push('/HelpWrap', '公告中心')
    }

    render() {
        const { list } = this.state;
        const seting = {
            autoplay: true,
            autoplaySpeed: 5000,
            dotPosition: 'left',
            loop: true,
            dots: false,
        }
        return (
            <div>
                {
                    list.length > 0 ? <div className="Notice">
                        <p />
                        <div style={{ lineHeight: '48px' }}> | &nbsp;</div>
                        <div style={{ width: '100%' }}>
                            <Carousel
                                {...seting}

                            >
                                {
                                    list.length !== 0 ? _.map(list, (item, index) => {
                                        return (
                                            <div key={index}>
                                                <ul key={index} className="Notice-content">
                                                    <li>
                                                        {item.title}
                                                    </li>
                                                    <li>
                                                        <span className="m-hide">{item.effectiveDate}</span>
                                                        <span
                                                            onClick={this.toHelpWrap}
                                                            style={{ marginRight: '10px' }}
                                                        >查看更多<RightOutlined /></span>
                                                    </li>
                                                </ul>
                                            </div>
                                        )
                                    }) : ''
                                }
                            </Carousel>

                        </div>
                    </div> : ''

                }

            </div>
        );
    }
}

export default withRouter(Notice);
