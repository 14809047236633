import React from 'react';
import './Mcity.scss';
import _ from 'lodash';
import $ from 'jquery';
import {Input} from 'antd';
import Axios from 'axios';
import Header from '../Header';
import {request, API_HOST_PRD} from '../../utils/Axios';
import {withRouter} from 'react-router';

const { Search } = Input;
class McityWrap extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            CityData: [],
            tipsData: [],
        }
    }
    componentDidMount() {
        //获取定位城市
        const {BMapGL} = window;
        var myCity = new BMapGL.LocalCity();
        myCity.get(function (result) {
            sessionStorage.setItem('curCityDYName', result.name);
        });
        request({
            url: '/product/areas/group'
        })
            .then((res) => {
                this.setState({
                    CityData: res
                })
                _.map(res, (item) => {
                    _.map(item.data, (items, index) => {
                        if (items.CITY_NAME === sessionStorage.getItem('curCityDYName')){
                            sessionStorage.setItem('curCityId', items.CITY_CD)
                        }
                    })
                })
            })
        sessionStorage.removeItem('curCityId')
        sessionStorage.removeItem('curCityCHName')
    }
    backPage = (data) => {
        sessionStorage.setItem('curCityCHName',data.CITY_NAME)
        sessionStorage.setItem('curCityId',data.CITY_CD)
        this.props.history.go(-1)
    }
    //筛选城市
    sendCityName = (e) => {
        request({
            url: '/product/areas/that/group?condition=' + e.target.value,
        })
            .then((res) => {
                this.setState({
                    CityData: res
                })
            })
        sessionStorage.setItem('curCityCHName',e.target.value)
    }
    //定位城市
    backPages = (e) => {
        sessionStorage.setItem('curCityCHName',sessionStorage.getItem('curCityDYName'))
        this.props.history.go(-1)
    }
    render() {
        const {CityData, tipsData} = this.state;
        return (
            <div>
                <Header />
                <div className="McityWrap">
                    <ul className="McityWrap-tip">
                        {
                            _.map(_.uniq(tipsData), (Msg, I) => {
                                return(
                                    <li key={I}>{_.uniq(Msg)}</li>
                                )
                            })
                        }
                    </ul>
                    <div className='searchCity'>
                        <Search
                            placeholder="请输入城市"
                            enterButton="Search"
                            size="large"
                            onPressEnter={this.sendCityName.bind(this)}
                        />
                    </div>
                    <ol>
                        <li>
                            <p>定位城市</p>
                            <span onClick={this.backPages.bind(this)}>{sessionStorage.getItem('curCityDYName')}</span>
                        </li>
                        {
                            _.map(CityData, (item, index) => {
                                return (
                                    <li key={index}>
                                        <p>{_.uniq(item.name)}</p>
                                        {
                                            _.map(item.data, (item, index) => {
                                                return(
                                                    <span onClick={this.backPage.bind(this, item)} key={index}>
                                                        {item.CITY_NAME}
                                                    </span>
                                                )
                                            })
                                        }
                                    </li>
                                )
                            })
                        }
                    </ol>
                </div>
            </div>
        );
    }
}

export default withRouter(McityWrap);
