import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import $ from 'jquery';
import { Tabs, Button, PageHeader, Popover, Empty } from 'antd';
import { withRouter } from 'react-router';
import { CaretDownOutlined } from '@ant-design/icons';
import Pic from '../../assets/images/dy-pic.png';
import MBG from '../../assets/images/dy-mr-bg.jpeg';
import { request, requestPost } from '../../utils/Axios';
import Downicon from '../../assets/images/down-icon.png';
import HomeIcon from '../../assets/images/home-icon2.png';
import './FilmWrap.scss';

const { TabPane } = Tabs;
let API_HOST_PRD_IMG = ''

class FilmWrap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DetailsData: [],
            saleData: [],
            ImmediateData: [],
            testData: [
                { img: Pic, name: '误杀', time: '3月28日' }
            ],
            pageCount: 9,
            pageCountR: 9,
            DataListL: [],
            DataListR: [],
            cityCD: '',
            // titleBar
            cityData: [],
            curCityCHNames: sessionStorage.getItem('curCityCHName'),
            cityVisible: false,
        };
    }

    componentDidMount() {
        if (window.screen.width > 450) {
            this.setState({
                pageCount: 15,
                pageCountR: 15
            })
        } else {
            this.setState({
                pageCount: 9,
                pageCountR: 9
            })
        }
        const { cityCD } = this.state;
        //    获取定位城市
        this.getCityData();
        const { BMapGL } = window;
        var myCity = new BMapGL.LocalCity();
        myCity.get(function (result) {
            sessionStorage.setItem('curCityDYName', result.name);
        });
        // 活动电影列表
        this.FilmWrapL(cityCD && cityCD ? cityCD : sessionStorage.getItem('curCityId'));
        this.FilmWrapR(cityCD && cityCD ? cityCD : sessionStorage.getItem('curCityId'));
        setTimeout(() => {
            this.getCityData();
            this.FilmWrapL(cityCD && cityCD ? cityCD : sessionStorage.getItem('curCityId'));
            this.FilmWrapR(cityCD && cityCD ? cityCD : sessionStorage.getItem('curCityId'));
        }, 10)
        window.addEventListener('resize', this.resizeBind);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeBind);
    }

    resizeBind = () => {
        if (window.innerWidth < 750) {
            $('.DY-text-show').hide();
        } else {
            $('.DY-text-show').show();
        }
    };
    FilmToDetails = (id) => {
        const { history } = this.props;
        history.push('/FilmWrap/FilmDetails');
        sessionStorage.setItem('FilmDetailsId', id);
        sessionStorage.setItem('FilmDetailsCode', '1');
    };
    FilmToDetailsM = (id) => {
        const { history } = this.props;
        history.push('/FilmWrap/FilmDetails');
        sessionStorage.setItem('FilmDetailsId', id);
        sessionStorage.setItem('FilmDetailsCode', '1');

    };
    INFilmToDetails = (id) => {
        const { history } = this.props;
        history.push('/FilmWrap/FilmDetails');
        sessionStorage.setItem('FilmDetailsId', id);
        sessionStorage.setItem('FilmDetailsCode', '2');
    };
    INFilmToDetailsM = (id) => {
        const { history } = this.props;
        history.push('/FilmWrap/FilmDetails');
        sessionStorage.setItem('FilmDetailsId', id);
        sessionStorage.setItem('FilmDetailsCode', '2');
    };


    loadingMore = (n) => {
        console.log(n)
        let a = 0
        if (window.screen.width > 450) {
            a = n + 5
        } else {
            a = n + 3
        }

        this.setState({
            pageCount: a
        });
        const { DataListL } = this.state;
        if (n > DataListL.length) {
            $('.moreBtnL').hide();
        }
    };
    loadingMoreR = (n) => {
        let a = 0
        if (window.screen.width > 450) {
            a = n + 5
        } else {
            a = n + 3
        }
        this.setState({
            pageCountR: a
        });
        const { DataListR } = this.state;
        if (n > DataListR.length) {
            $('.moreBtnR').hide();
        }
    };
    //取城市cd
    getCityData = () => {
        request({
            url: '/product/areas/group'
        })
            .then((res) => {
                this.setState({
                    cityData: res
                });
                _.map(res, (item) => {
                    _.map(item.data, (items) => {
                        if (items.CITY_NAME === sessionStorage.getItem('curCityCHName')) {
                            sessionStorage.setItem('curCityId', items.CITY_CD)
                            this.setState({
                                cityCD: items.CITY_CD,
                            })
                            this.FilmWrapL(items.CITY_CD)
                            this.FilmWrapR(items.CITY_CD)
                        }
                    })
                })
            })
    };

    // 在售电影
    FilmWrapL = (prCity) => {
        request({
            url: '/party/api/getPropertyGetByPropertyId?resourceId=DOMAIN_IMG_SSL&propertyId=DOMAIN_IMG_SSL',
        })
            .then((res) => {
                API_HOST_PRD_IMG = res.systemPropertyValue
                request({
                    url: '/product/plans?chnlNo=05&prCity=' + prCity
                })
                    .then((res) => {
                        if (res.code === 200) {
                            this.setState({
                                DataListL: res.data.datas
                            })
                        }
                    })
            });
    };
    // 即映影片
    FilmWrapR = (prCity) => {
        console.log(prCity)
        requestPost({
            url: '/product/movie/list-soon',
            data: {
                productTypeCd: '3',
                sortField: {
                    fieldName: 'releaseDate',
                    sortOrder: 'asc',
                },
                cityCd: prCity ? prCity : 226,
                chnlNo: '05',
            }
        })
            .then((res) => {
                if (res.code === 200) {
                    console.log(res, '------------------')
                    if (res.data.datas) {
                        this.setState({
                            DataListR: res.data.datas.slice(0, 8)
                        });
                    } else {
                        this.setState({
                            DataListR: res.data.content.slice(0, 8)
                        });
                    }
                }
            });
    };

    //设置城市visible
    setCityVisible = () => {
        this.setState({
            cityVisible: true
        })
    }
    //设置城市
    curCityCHNames = (city) => {
        this.setState({
            curCityCHNames: city.CITY_NAME,
            cityVisible: false
        });
        sessionStorage.setItem('curCityCHName', city.CITY_NAME);
        sessionStorage.setItem('curCityId', city.CITY_CD);
        this.FilmWrapL(city.CITY_CD)
        this.FilmWrapR(city.CITY_CD)
    };
    //选择城市
    toCity = () => {
        this.props.history.push('/McityWrap');
        sessionStorage.setItem('curPage', 'DY')
    }
    render() {
        const {
            DataListL,
            DataListR,
            pageCount,
            pageCountR,
            cityData,
            curCityCHNames,
            cityVisible
        } = this.state;
        const CityWrap = (
            <div className="City-content">
                {
                    _.map(cityData, (item, index) => {
                        return (
                            <div style={{ overflowY: 'scroll' }} key={index}>
                                <div className="City-content-name">
                                    <p key={index}>{item.name}</p>
                                    {
                                        _.map(item.data, (item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="City-content-names"
                                                    onClick={this.curCityCHNames.bind(this, item)}
                                                >
                                                    {item.CITY_NAME}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
        const routes = [
            {
                path: '/',
                breadcrumbName: '首页'
            },
            {
                path: '/FilmWrap',
                breadcrumbName: '电影'
            }
        ];
        return (
            <div>
                <div className="titleBar" style={{ padding: '0 13rem' }}>
                    <div className="site-page-header-ghost-wrapper">
                        <img src={HomeIcon} style={{ marginRight: 8 }} onClick={this.toHome} alt="" className="Home-Icon" />
                        <PageHeader
                            ghost={false}
                            onClick={this.toHome}
                            extra={[
                                <div className="CityWrap">
                                    <div className="CityWrap-P">
                                        <Popover
                                            placement="bottomRight"
                                            style={{ top: '120px' }}
                                            content={CityWrap}
                                            trigger="click"
                                            visible={cityVisible}
                                        >
                                            <div>
                                                <span onClick={this.setCityVisible}>{curCityCHNames}</span>
                                                <CaretDownOutlined onClick={() => { this.setState({ cityVisible: false }) }} />
                                            </div>
                                        </Popover>
                                    </div>
                                    <div className="CityWrap-M">
                                        <div onClick={this.toCity}>{sessionStorage.getItem('curCityCHName')}</div>
                                        <CaretDownOutlined onClick={this.toCity} />
                                    </div>
                                </div>
                            ]}
                            breadcrumb={{ routes }}
                        >
                        </PageHeader>
                    </div>
                </div>

                <div className="FilmWrap">
                    <Tabs defaultActiveKey="1" animated={false}>
                        <TabPane tab="在售影片" key="1">
                            <div className="FilmWrap-Content FilmWrap-Content-left">
                                {
                                    DataListL.length > 0 ?
                                        _.slice(DataListL, 0, pageCount).map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="FilmWrap-P">
                                                        <dl>
                                                            <dt>
                                                                <div className="DY-img-show">
                                                                    <img src={API_HOST_PRD_IMG + item.imgUrl}
                                                                        alt="" />
                                                                </div>
                                                                <div className="DY-text-show">
                                                                    <div>
                                                                        <p>{item.description}</p>
                                                                        <Button
                                                                            onClick={this.FilmToDetails.bind(this, item.movId)}>
                                                                            查看详情
                                                                        </Button>
                                                                    </div>
                                                                    <div>
                                                                        <span>电影评分：{item.productRating}</span>
                                                                    </div>
                                                                </div>
                                                            </dt>
                                                            <dd>{item.productName}</dd>
                                                        </dl>
                                                    </div>
                                                    <div className="FilmWrap-M">
                                                        <dl key={index}
                                                            onClick={this.FilmToDetailsM.bind(this, item.movId)}>
                                                            <dt>
                                                                <div className="DY-img-show">
                                                                    <img src={API_HOST_PRD_IMG + item.imgUrl}
                                                                        alt="" />
                                                                </div>
                                                                <div className="DY-text-show">
                                                                    <div>
                                                                        <p>{item.description}</p>
                                                                        <Button>
                                                                            查看详情
                                                                        </Button>
                                                                    </div>
                                                                    <div>
                                                                        <span>电影评分：{item.productRating}</span>
                                                                    </div>
                                                                </div>
                                                            </dt>
                                                            <dd>{item.productName}</dd>
                                                        </dl>
                                                    </div>
                                                </div>

                                            );
                                        }) : ""
                                    // <div style={{marginTop: 50}}>
                                    //     <Empty description="暂无数据"/>
                                    // </div>
                                }
                            </div>
                            {
                                DataListL.length > 12 ?
                                    <div
                                        className="moreBtn moreBtnL"
                                        onClick={this.loadingMore.bind(this, pageCount)}>
                                        <b>查看更多影片</b>
                                        <i><img src={Downicon} alt="" /></i>
                                    </div>
                                    : ''
                            }
                        </TabPane>
                        <TabPane tab="即映影片" key="2">
                            <div className="FilmWrap-Content FilmWrap-Content-right">
                                {
                                    DataListR.length !== 0 ?
                                        _.slice(DataListR, 0, pageCount).map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="FilmWrap-P">
                                                        <dl key={index}>
                                                            <dt>
                                                                <div className="DY-img-show">
                                                                    <img
                                                                        src={API_HOST_PRD_IMG + item.smallImageUrl}
                                                                        alt="" />
                                                                </div>
                                                                <div className="DY-text-show">
                                                                    <div>
                                                                        <p>{item.description}</p>
                                                                        <Button
                                                                            onClick={this.INFilmToDetails.bind(this, item.id)}>
                                                                            查看详情
                                                                        </Button>
                                                                    </div>
                                                                    <div>
                                                                        <span>{item.likeCnt + '人想看'}</span>
                                                                    </div>
                                                                </div>
                                                            </dt>
                                                            <dd>
                                                                <div>{item.productName}</div>
                                                                <div>{moment(item.releaseDate).format('YYYY-MM-DD') + '上映'}</div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                    <div className="FilmWrap-M">
                                                        <dl
                                                            key={index}
                                                            onClick={this.INFilmToDetailsM.bind(this, item.id)}>
                                                            <dt>
                                                                <div className="DY-img-show">
                                                                    {
                                                                        item.smallImageUrl ?
                                                                            <img
                                                                                src={API_HOST_PRD_IMG + item.smallImageUrl}
                                                                                alt="" />
                                                                            :
                                                                            <img src={MBG} alt='' />
                                                                    }
                                                                </div>
                                                                <div className="DY-text-show">
                                                                    <div>
                                                                        <p>{item.description}</p>
                                                                        <Button>
                                                                            查看详情
                                                                        </Button>
                                                                    </div>
                                                                    <div>
                                                                        <span>{item.likeCnt + '人想看'}</span>
                                                                    </div>
                                                                </div>
                                                            </dt>
                                                            <dd>
                                                                <div>{item.productName}</div>
                                                                <div>{moment(item.releaseDate).format('YYYY-MM-DD') + '上映'}</div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>

                                            );
                                        }) : ""
                                    // <div style={{marginTop: 50}}>
                                    //     <Empty description="暂无数据"/>
                                    // </div>
                                }
                            </div>
                            {
                                DataListR.length > 12 ?
                                    <div className="moreBtn moreBtnR"
                                        onClick={this.loadingMoreR.bind(this, pageCountR)}>
                                        <b>查看更多影片</b>
                                        <i><img src={Downicon} alt="" /></i>
                                    </div>
                                    : ''
                            }
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default withRouter(FilmWrap);
