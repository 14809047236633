import React from 'react';
import _ from 'lodash';
import Axios from 'axios';
import $ from 'jquery';
import { Carousel, PageHeader, Popover, Tabs } from 'antd';
import {
    CaretDownOutlined
} from '@ant-design/icons';
import { request, API_HOST_PRD } from '../../utils/Axios';
import Pic2 from '../../assets/images/yc-bg.png';
import Pic3 from '../../assets/images/dy-pic.png';
import HomeIcon from '../../assets/images/home-icon.png';
//icon
import Icon1 from '../../assets/images/YC-icon/icon1.png';
import Icon2 from '../../assets/images/YC-icon/icon2.png';
import Icon3 from '../../assets/images/YC-icon/icon3.png';
import Icon4 from '../../assets/images/YC-icon/icon4.png';
import Ticon from '../../assets/images/YC-icon/top-icon.png';
import SwiperBox from 'react-id-swiper';
import Swiper from 'swiper';
import MBG from '../../assets/images/dy-mr-bg.jpeg';
import './StudiosWrap.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
let API_HOST_PRD_IMG = '';
let baiduYn='1';

class StudiosWrap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cityData: [],   // 影城数据
            slidesToShowCount: 5,
            count: '1',
            datalist: [],   // 影城详情信息
            SpecialEffectsData: [],  //特效厅
            FriendcardData: [], // 朋友卡
            mapName: '',
            mapAddres: '',
            addresX: 40,
            addresY: 116,
            StudiosMCount: 5,
            StudiosMCountTop: 5,
            curImg: '',  // 缩略图大图
            //    titleBar
            cityAddresData: [],
            curCityCHNames: sessionStorage.getItem('curCityCHName'),
            cityDataLists: [],
            curCityID: '', // 城市cd
            cityVisible: false,
            curimgYC: '', //当前影城图片
            mode: '',
            curTitleName: '影城',
        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    componentDidMount() {
        sessionStorage.removeItem('curPage');
        //初始化缩略图
        this.setState({
            curImg: Pic2
        });
        //重置显示数量
        if (window.innerWidth < 750) {
            this.setState({
                StudiosMCount: 1,
                StudiosMCountTop: 2

            });
        } else {
            this.setState({
                StudiosMCount: 5,
                StudiosMCountTop: 5
            });
        }
        //    获取定位城市
        this.getCityData();
        this.getBaiduYn();
        //    特效厅type
        this.curTypeNAme('0');
        this.getStudiosData(226)
        //    init swiper 影城数据
        setTimeout(() => {
            new Swiper('.swiper-container', {
                slidesPerView: this.state.StudiosMCountTop,
                spaceBetween: 12,
                navigation: {//左右分页
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                observer: true,
                observeParents: true,
                // centeredSlides: true,
                // centeredSlidesBounds: true,
                freeMode: true,
            });
        }, 0);
        this.getList()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeBind);
    }
    getList = () => {
        request({
            url: '/party/api/getPropertyGetByPropertyId?resourceId=DOMAIN_IMG_SSL&propertyId=DOMAIN_IMG_SSL',
        })
            .then((res) => {
                API_HOST_PRD_IMG = res.systemPropertyValue
                request({
                    url: '/content/api/homepageMaster/query?type=SPECIALSCREEN&city='
                })
                    .then((res) => {
                        if (res.data && res.data.length > 0) {
                            this.setState({
                                SpecialEffectsData: res.data
                            })
                        }
                        console.log(this.state.SpecialEffectsData)
                    })
            });
    }
    // 影城信息
    getStudiosData = (id) => {
        request({
            url: '/product/thats?cityCd=' + id
        })
            .then((res) => {
                this.setState({
                    cityData: res,
                    datalist: res[0]
                });
                if (this.state.datalist) {
                    const initMapData = this.state.datalist;
                    let imgList = initMapData.facilityImgs ? initMapData.facilityImgs.split(',') : []
                    this.setState({
                        curimgYC: imgList[0]
                    });
                    if (initMapData !== undefined || initMapData) {
                        this.MapFun(initMapData.thatNm, initMapData.thatAddr, initMapData.latit, initMapData.lngt);
                    }
                }

            });
    };
    //cur type
    curTypeNAme = (key) => {
        this.setState({
            mode: key
        });
    };

    getBaiduYn = () => {
        request({
            url: '/party/api/getPropertyGetByPropertyId?resourceId=sys.app.map.baidu.useYn&propertyId=sys.app.map.baidu.useYn',
        })
            .then((res) => {
                    baiduYn= res.systemPropertyValue
            });
    }
    //切换影城
    curContent = (data) => {
        console.log("ceshicesssss")
        this.setState({
            curimgYC: ''
        });
        this.setState({
            datalist: data
        });
        let imgList = data.facilityImgs ? data.facilityImgs.split(',') : []
        if (imgList.length > 0) {
            this.setState({
                curimgYC: imgList[0]
            });
        }
        let mapName = data.thatNm;
        let mapAddres = data.thatAddr;
        let addresX = data.latit;
        let addresY = data.lngt;

        window.addEventListener('resize', this.resizeBind);
        console.log("生成百度地图2"+baiduYn)
        if(baiduYn=="1"){
            this.MapFun(mapName, mapAddres, addresX, addresY);
        }

    };
    // 地图初始化
    MapFun = (mapName, mapAddres, addresX, addresY) => {

        console.log("生成百度地图11111"+baiduYn)
        if(baiduYn=="0"){
            console.log("生成百度地图关闭"+baiduYn)
            return false;
        }
        // Map init
        const { BMapGL } = window;
        const map = new BMapGL.Map('allmap');
        map.centerAndZoom(new BMapGL.Point(addresY, addresX), 20);
        map.enableScrollWheelZoom(false);
        // map.setHeading(64.5);
        // map.setTilt(73);
        var opts = {
            width: 100,     // 信息窗口宽度
            height: 'auto',    // 信息窗口高度
            title: `${mapName}`  // 信息窗口标题
        };
        var infoWindow = new BMapGL.InfoWindow(mapAddres, opts);  // 创建信息窗口对象
        map.openInfoWindow(infoWindow, map.getCenter());        // 打开信息窗口
        var point = new BMapGL.Point(addresY, addresX);
        var marker = new BMapGL.Marker(point);  // 创建标注
        map.addOverlay(marker);               // 将标注添加到地图中
    };
    toStudiosCurImg = (data) => {
        this.setState({
            curImg: data.img
        });
    };
    //titleBar
    getCityData = () => {
        request({
            url: '/product/areas/group'
        })
            .then((res) => {
                this.setState({
                    cityAddresData: res
                });
                _.map(res, (m) => {
                    _.map(m.data, (n) => {
                        if (n.CITY_NAME === sessionStorage.getItem('curCityCHName')) {
                            this.getStudiosData(n.CITY_CD);
                            this.checkCityData(n.CITY_CD);
                        }
                    });
                });
            });
    };
    //P - 点击设置城市
    setCityStatus = () => {
        this.setState({
            cityVisible: true
        });
    };
    curCityCHNames = (city) => {
        this.setState({
            curCityCHNames: city.CITY_NAME,
            curCityID: city.CITY_CD,
            cityVisible: false
        });
        //切换城市选择影城
        this.checkCityData(city.CITY_CD);
        sessionStorage.setItem('curCityCHName', city.CITY_NAME);
    };
    //切换城市影城
    checkCityData = (id) => {
        request({
            url: '/product/thats?cityCd=' + id
        })
            .then((res) => {
                let M = res;
                this.setState({
                    cityDataLists: res,
                    datalist: res[0]
                });
                if (this.state.datalist) {
                    const initMapData = this.state.datalist;
                    let imgList = initMapData.facilityImgs ? initMapData.facilityImgs.split(',') : []
                    this.setState({
                        curimgYC: imgList[0]
                    });
                    if(baiduYn==1){
                        console.log("生成百度地图"+baiduYn)
                        if (initMapData !== undefined || initMapData) {
                            this.MapFun(initMapData.cityName, initMapData.thatAddr, initMapData.latit, initMapData.lngt);
                        }
                    }

                }
            });
    };
    toCity = () => {
        this.props.history.push('/McityWrap');
        sessionStorage.setItem('curPage', 'YC');
    };
    //切换当前影城图片
    curYCImg = (data) => {
        this.setState({
            curimgYC: data
        });
    };
    //判断当然小标题是否显示
    curCallCack = (key) => {
        if (key === '2') {
            this.setState({
                curTitleName: '特效厅'
            });
        } else {
            this.setState({
                curTitleName: '影城'
            });
        }
    };
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {
        const {
            cityData,
            datalist,
            StudiosMCount,
            cityAddresData,
            curCityCHNames,
            cityDataLists,
            cityVisible,
            curimgYC,
            mode,
            curTitleName,
            SpecialEffectsData

        } = this.state;
        const routes = [
            {
                path: '/',
                breadcrumbName: '首页'
            },
            {
                path: '/StudiosWrap',
                breadcrumbName: '影城'
            },
            {
                path: '',
                breadcrumbName: curTitleName
            }
        ];
        const CityWrap = (
            <div className="City-content">
                {
                    _.map(cityAddresData, (item, index) => {
                        return (
                            <div style={{ overflowY: 'scroll' }} key={index}>
                                <div className="City-content-name">
                                    <p key={index}>{item.name}</p>
                                    {
                                        _.map(item.data, (item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="City-content-names"
                                                    onClick={this.curCityCHNames.bind(this, item)}
                                                >
                                                    {item.CITY_NAME}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
        const params = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            center: false
        };
        return (
            <div>
                <div className="titleBar" style={{ padding: '0 13rem' }}>
                    <div className="site-page-header-ghost-wrapper">
                        <img
                            src={HomeIcon}
                            onClick={this.toHome}
                            alt=""
                            className="Home-Icon"
                            style={{ marginRight: '8px' }}
                        />
                        <PageHeader
                            ghost={false}
                            onClick={this.toHome}
                            extra={[
                                <div className="CityWrap">
                                    <div className="CityWrap-P">
                                        <Popover
                                            placement="bottomRight"
                                            style={{ top: '120px' }}
                                            content={CityWrap}
                                            trigger="click"
                                            visible={cityVisible}
                                        >
                                            <div
                                                style={{ marginRight: '10px' }}
                                                onClick={this.setCityStatus}
                                            >
                                                {curCityCHNames}
                                                <CaretDownOutlined />
                                            </div>
                                        </Popover>
                                    </div>
                                    <div className="CityWrap-M">
                                        <div onClick={this.toCity}>{sessionStorage.getItem('curCityCHName')}</div>
                                        <CaretDownOutlined onClick={this.toCity} />
                                    </div>
                                </div>
                            ]}
                            breadcrumb={{ routes }}
                        >
                        </PageHeader>
                    </div>
                </div>
                <div className="StudiosWrap">
                    <Tabs
                        className="topTab"
                        defaultActiveKey="1"
                        animated={false}
                        onChange={this.curCallCack}
                    >
                        <TabPane tab="影城" key="1">
                            <div style={{ position: 'relative' }}>
                                <p style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                                    {
                                        cityData.length !== 0 ? <span>所在地区的影城({cityDataLists.length})</span> : ''
                                    }
                                </p>
                                <div className="swiper-container" style={{ overflow: 'hidden' }}>
                                    <div className="swiper-wrapper">
                                        {
                                            _.map(cityDataLists, (item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="swiper-slide cityVideos"
                                                    >
                                                        <p onClick={this.curContent.bind(this, item)}>
                                                            <img
                                                                src={Ticon}
                                                                alt=''
                                                                style={{
                                                                    width: '100%',
                                                                    height: 100,
                                                                    borderRadius: 10
                                                                }}
                                                            />
                                                            <span style={{ borderRadius: '10px' }}>{item.thatNm}</span>
                                                        </p>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                    <div className="swiper-button-prev swiper-button-white" />
                                    <div
                                        className="swiper-button-next swiper-button-white"
                                        style={{
                                            right: 0
                                        }}
                                    />
                                </div>
                            </div>
                            {
                                datalist ? <div className="StudiosWrap-content">
                                    <p className="StudiosWrap-content-title">{datalist.thatNm}</p>
                                    <div className="StudiosWrap-content-wrap">
                                        <div className="content-left"

                                        >
                                            <div
                                                className="m-hide"
                                            >
                                                <p style={{
                                                    height: '300px',
                                                    textAlign: 'center',
                                                    marginBottom: '20px'
                                                }}>
                                                    <img
                                                        style={{ height: '100%', width: '100%' }}
                                                        src={API_HOST_PRD_IMG + curimgYC}
                                                        alt=''
                                                    />
                                                </p>
                                            </div>
                                            <div>
                                            </div>
                                            <div style={{ position: 'relative' }}>
                                                <Slider ref={c => (this.slider = c)} {...params}>
                                                    {
                                                        _.map(datalist.facilityImgs ? datalist.facilityImgs.split(',') : [], (item, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    onClick={this.curYCImg.bind(this, item)}
                                                                    className="slick-img-box"
                                                                >
                                                                    <img
                                                                        className="M-img"
                                                                        src={API_HOST_PRD_IMG + item}
                                                                        alt=''
                                                                        style={{
                                                                            height: '100px'
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Slider>

                                                <span className="slider-btn-S left-slider-btn-S" onClick={this.previous}><LeftOutlined style={{ color: '#fff' }} /></span>
                                                <span className="slider-btn-S right-slider-btn-S" onClick={this.next}><RightOutlined style={{ color: '#fff' }} /></span>
                                            </div>
                                        </div>
                                        <div className="content-right-text">
                                            {
                                                datalist.length !== 0 ?
                                                    <ol>
                                                        <li>
                                                            <p>影城信息</p>
                                                        </li>
                                                        {
                                                            datalist.thatAddr ? <li>
                                                                <span>
                                                                    <img src={Icon1} alt='' />
                                                            地址：
                                                        </span>
                                                                <em>{datalist.thatAddr}</em>
                                                            </li> : ''
                                                        }
                                                        {
                                                            datalist.houseNumber ? <li>
                                                                <span>
                                                                    <img src={Icon2} alt='' />
                                                            电话：</span>
                                                                <em>{datalist.houseNumber}</em>
                                                            </li>
                                                                : ''
                                                        }
                                                        {
                                                            datalist.screenCnt ?
                                                                <li>
                                                                    <span>
                                                                        <img src={Icon3} alt='' />
                                                                影厅：</span>
                                                                    <em>{datalist.screenCnt + '个'}</em>
                                                                </li> : ''
                                                        }
                                                        {
                                                            datalist.seatCnt ?
                                                                <li>
                                                                    <span>
                                                                        <img src={Icon4} alt='' />
                                                             座位：</span>
                                                                    <em>{datalist.seatCnt + '个'}</em>
                                                                </li> : ''
                                                        }
                                                        {
                                                            datalist.traficInfo ?
                                                                <span>
                                                                    <li style={{ marginTop: 20 }}>
                                                                        <p>交通信息</p>
                                                                    </li>
                                                                    <li>
                                                                        {datalist.traficInfo}
                                                                    </li>
                                                                </span> : <li></li>
                                                        }
                                                    </ol>
                                                    : ''
                                            }
                                        </div>
                                    </div>
                                </div> : null
                            }

                            {
                                datalist ? <div className="content-list">

                                    {
                                        datalist.length !== 0 ?
                                            <h6>特色服务</h6>
                                            : ''
                                    }
                                    <div className="content-list-count">
                                        <ul>
                                            <li>
                                                <p>朋友卡</p>
                                                <span>购买朋友卡享受购票优惠</span>
                                            </li>
                                            <li>
                                                <p>观影小食</p>
                                                <span>支持线上购买观影小食</span>
                                            </li>
                                            <li>
                                                <p>特效厅</p>
                                                <div>
                                                    {
                                                        datalist.specScreens ?
                                                            _.map(datalist.specScreens, (item, index) => {
                                                                return (
                                                                    <span key={index}
                                                                        style={{ width: '30%' }}>{item.specScreenName}</span>
                                                                );
                                                            })
                                                            : ''
                                                    }
                                                </div>
                                            </li>
                                            {
                                                datalist.parking ?
                                                    <li>
                                                        <p>停车场</p>
                                                        <span>{datalist.parking}</span>
                                                    </li>
                                                    : ''
                                            }
                                            {
                                                datalist.surroundingCatering ?
                                                    <li>
                                                        <p>周边餐饮</p>
                                                        <span>{datalist.surroundingCatering}</span>
                                                    </li>
                                                    : ''
                                            }
                                            {
                                                datalist.childReductions ?
                                                    <li>
                                                        <p>儿童优惠</p>
                                                        <span>{datalist.childReductions}</span>
                                                    </li>
                                                    : ''
                                            }
                                            {
                                                datalist.eyes3d ?
                                                    <li>
                                                        <p>3D眼镜</p>
                                                        <span>{datalist.eyes3d}</span>
                                                    </li>
                                                    : ''
                                            }
                                            {
                                                datalist.wifi ?
                                                    <li>
                                                        <p>Wi-Fi</p>
                                                        <span>{datalist.wifi}</span>
                                                    </li>
                                                    : ''
                                            }
                                            {
                                                datalist.other ?
                                                    <li>
                                                        <p>其他</p>
                                                        <span>{datalist.other}</span>
                                                    </li>
                                                    : ''
                                            }
                                        </ul>
                                    </div>
                                </div> : null
                            }
                            {
                                datalist&&baiduYn==1 ? <div className="AddressWrap">
                                    {
                                        datalist.length !== 0 ?
                                            <p>影城位置</p>
                                            : ''
                                    }
                                    {
                                        datalist.length !== 0 ?
                                            <div id="allmap" />
                                            : ''
                                    }

                                </div>
                                    : null
                            }
                        </TabPane>
                        <TabPane tab="特效厅" key="2">
                            <div className="SpecialEffects-Content">
                                <p>特效厅介绍</p>
                                <div className="Special-content-title">
                                    CJ CGV的品牌理念是“超越电影的感动”, 为顾客送上超越电影观赏的享受和乐趣。除了有口皆碑的国际化装修风格和先进设备之外，
                                    CGV的放映厅更是别具一格丰富多样。除了常规的2D和3D影厅，CGV的IMAX巨幕厅让你体会到身临其境的观影享受；
                                    CGV的4DX厅带来超越3D的生动五感体验；CGV的杜比影院（Dolby Cinema）通过高动态范围和更广的色域呈现生动鲜活的故事画面；
                                    CGV更有曾创下数字放映吉尼斯世界记录的STARIUM视达巨幕厅以及突破性的弧形沉浸式电影体验馆SPHEREX厅，还有为甜蜜而特别打造的温情观影空间SWEETBOX情侣厅，高端放映厅与咖啡厅的完美结合GOLD
                                    CLASS贵宾厅等。
                                        </div>

                                <div className="Special-content-tabs">
                                    <div>
                                        <Tabs
                                            defaultActiveKey="0"
                                            onTabClick={this.curTypeNAme.bind(this)}
                                            tabPosition={mode}
                                        >
                                            {
                                                SpecialEffectsData.map((screen, sIndex) => {
                                                    return <TabPane tab={screen.title} key={sIndex}>
                                                        <p style={{ color: '#333' }}>{screen.title}</p>
                                                        <Carousel
                                                            draggable={true}
                                                            autoplay={2000}
                                                        >
                                                            {
                                                                _.map(screen.imageurl ? screen.imageurl.split(',') : [], (pic, ind) => {
                                                                    return (
                                                                        <dl key={ind} className="YT-content-img">
                                                                            <dt>
                                                                                <img
                                                                                    src={API_HOST_PRD_IMG + pic}
                                                                                    alt='' />
                                                                            </dt>
                                                                            <dd>
                                                                                <p>{screen.content}</p>
                                                                            </dd>
                                                                        </dl>
                                                                    );
                                                                })
                                                            }
                                                        </Carousel>

                                                    </TabPane>
                                                })
                                            }
                                        </Tabs>
                                    </div>

                                </div>
                            </div>
                        </TabPane>
                    </Tabs>

                </div>
            </div>

        );
    }
}

export default StudiosWrap;
