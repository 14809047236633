import React from "react";
import _ from 'lodash';
import $ from 'jquery';
import { Modal, PageHeader, Empty } from 'antd';
import { request } from '../../utils/Axios';
import { SoundOutlined } from '@ant-design/icons';
import Pic1 from '../../assets/images/IMAX主视觉.png';
import DownIcon from '../../assets/images/down-icon.png';
import HomeIcon from '../../assets/images/home-icon.png';
import './Help.scss';

class HelpWrap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            list: [],
            DetailsData: {},
            dataCount: 10,
        }
    }
    componentDidMount() {
        this.getData();
    }

    toHelpDetails = (Msg) => {
        this.setState({
            visible: true,
            DetailsData: Msg
        })
    };

    getData = () => {
        request({
            url: '/content/api/announcement/query?channel=HOMEPAGE&thatcd='
        })
            .then((res) => {
                if (res.code === 200) {
                    this.setState({
                        list: res.data
                    })
                }
            })
    };
    moreBtn = (n) => {
        let a = n + 4
        this.setState({
            dataCount: a
        })
        const { list } = this.state;
        if (n > list.length) {
            $('.loadingMore').hide()
        }
    }
    render() {
        const { list, DetailsData, dataCount } = this.state;
        const routes = [
            {
                path: '/',
                breadcrumbName: '首页',
            },
            {
                path: '/HelpWrap',
                breadcrumbName: '公告中心'
            },
        ];
        return (
            <div className="HelpWrap">
                <div className="titleBar" style={{ padding: '0 13rem' }}>
                    <div className="site-page-header-ghost-wrapper">
                        <img src={HomeIcon} onClick={this.toHome} alt="" className="Home-Icon" />
                        <PageHeader
                            ghost={false}
                            onClick={this.toHome}
                            breadcrumb={{ routes }}
                        >
                        </PageHeader>
                    </div>
                </div>
                <div className="HelpWrap-content">
                    {
                        list.length !== 0 ?
                            <div>
                                <p>CGV公告</p>
                                <div className="HelpWrap-list">
                                    <ol>
                                        {
                                            _.slice(list, 0, dataCount).map((item, index) => {
                                                return (
                                                    <li key={index} onClick={this.toHelpDetails.bind(this, item)}>
                                                        <div>
                                                            <em><SoundOutlined />公告</em>
                                                            <i style={{ width: '50%' }}>{item.title}</i>
                                                        </div>
                                                        <div>
                                                            {item.effectiveDate}
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ol>
                                </div>
                            </div>
                            :
                            <div style={{ marginTop: 50 }}>
                                <Empty description="暂无数据" />
                            </div>
                    }
                    {
                        list.length > 10 ?
                            <div className="loadingMore" onClick={this.moreBtn.bind(this, dataCount)}>
                                <span>查看更多公告</span>
                                <i><img src={DownIcon} alt="" /></i>
                            </div>
                            : ''
                    }

                </div>
                {/*公告详情弹框*/}
                <Modal
                    maskClosable={false}
                    title={
                        <div className="HelpWrapDetails-title">
                            <ul
                                style={{
                                    display: 'block'
                                }}
                            >
                                <li>
                                    <span>
                                        <em
                                            style={{
                                                background: '#FC5869',
                                                color: '#fff',
                                                fontStyle: 'normal',
                                                borderRadius: '18px',
                                                padding: '2px 12px',
                                                marginRight: '12px'
                                            }}
                                        ><SoundOutlined />公告</em>
                                    </span>
                                    <span
                                        className="G-text"
                                        style={{
                                            fontWeight: 'normal',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            width: '180px',
                                            display: 'inline-block',
                                        }}>{DetailsData.title}</span>
                                </li>
                                <li>
                                    <span
                                        style={{
                                            fontWeight: 'normal',
                                            marginTop: 10,
                                            display: 'block',
                                            color: 'rgba(119,119,119,1)'
                                        }}>
                                        {DetailsData.effectiveDate}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    }
                    visible={this.state.visible}
                    onOk={() => { this.setState({ visible: false }) }}
                    onCancel={() => { this.setState({ visible: false }) }}
                    footer={<div />}
                >
                    <div className="HelpWrapDetails-content" dangerouslySetInnerHTML={{ __html: unescape(DetailsData.content) }}>

                    </div>
                </Modal>
            </div>
        )
    }
}

export default HelpWrap
