import React from 'react';
import { BackTop } from 'antd';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Home from "./Component/Home";
import Footer from "./Component/Footer";
import Header from "./Component/Header";
import FilmWrap from './Pages/FilmWrap';
import FilmDetails from "./Pages/FilmWrap/FilmDetails";
import ActivityWarp from "./Pages/ActivityWarp";
import ActivitDetails from './Pages/ActivityWarp/ActivitDetails'
import StudiosWrap from "./Pages/StudiosWrap";
import FriendWrap from './Pages/FriendWrap';
import Company from './Pages/Company';
import DevelopmentWrap from './Pages/DevelopmentWrap';
import RecruitmentWrap from './Pages/RecruitmentWrap';
import ContactWrap from './Pages/ContactWrap';
import HelpWrap from './Pages/HelpWrap';
import McityWrap from './Component/Mcity';
import './App.scss';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        const { BMapGL } = window;
        var myCity = new BMapGL.LocalCity();
        myCity.get(function (result) {
            sessionStorage.setItem('curCityDYName', result.name);
            sessionStorage.setItem('curCityCHName', result.name);
        });
    }

    // scrollTop
    handleScrollTop() {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
        });
    }
    render() {
        return (
            <Router>
                <Header />
                <div className="ContentMax">
                    <Switch>
                        <Route exact path='/' component={Home} />
                        {/*电影*/}
                        <Route exact path='/FilmWrap' component={FilmWrap} />
                        {/*电影详情*/}
                        <Route path='/FilmWrap/FilmDetails' component={FilmDetails} />
                        {/*活动*/}
                        <Route exact path='/ActivityWarp' component={ActivityWarp} />
                        {/*活动详情*/}
                        <Route exact path='/ActivityWarp/ActivitDetails' component={ActivitDetails} />
                        {/*影城*/}
                        <Route path='/StudiosWrap' component={StudiosWrap} />
                        {/*朋友*/}
                        <Route path='/FriendWrap' component={FriendWrap} />
                        {/*公司介绍*/}
                        <Route path='/Company' component={Company} />
                        {/*发展成就*/}
                        <Route path='/DevelopmentWrap' component={DevelopmentWrap} />
                        {/*诚聘英才*/}
                        <Route path='/RecruitmentWrap' component={RecruitmentWrap} />
                        {/*联系我们*/}
                        <Route path='/ContactWrap' component={ContactWrap} />
                        {/*公告中心*/}
                        <Route path='/HelpWrap' component={HelpWrap} />
                        {/*M-city*/}
                        <Route exact path='/McityWrap' component={McityWrap} />
                    </Switch>
                    <Footer />
                </div>

                <BackTop
                    className="ToUp"
                    visibilityHeight={600}
                    onClick={this.handleScrollTop}
                >
                    <em />
                    <div>TOP</div>
                </BackTop>
            </Router>
        );
    }
}

export default App;
