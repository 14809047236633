import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';
import './Footer.scss';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    };
    toCompany = () => {
        this.props.history.push('/Company', '公司介绍')
    }
    toContactWrap = () => {
        this.props.history.push('/ContactWrap', '联系我们')
    }
    toHelpWrap = () => {
        this.props.history.push('/HelpWrap', '公告中心')
    }
    toHgawab = () => {
        window.open('http://www.beian.gov.cn/')
    }
    toShgs = () => {
        // window.location.href = ''
    }
    toHicp = () => {
        window.open('https://beian.miit.gov.cn/#/Integrated/index')
    }
    render() {
        return (
            <footer className="footer">
                <div className="footer-logo">
                    <Link to="/" className="footer-logo-content" />
                </div>
                <div className="footer-list">
                    <div>
                        <ul>
                            <li onClick={this.toFilmWrap}><Link to="/FilmWrap">电影</Link> |</li>
                            <li onClick={this.toActivityWarp}><Link to="/ActivityWarp">活动</Link> |</li>
                            <li onClick={this.toStudiosWrap}><Link to="/StudiosWrap">影城</Link> |</li>
                            <li onClick={this.toFriendWrap}><Link to="/FriendWrap">PACONNIE CLUB</Link> |</li>
                            <li onClick={this.toCompany}><Link to="/Company">公司介绍</Link> |</li>
                            <li onClick={this.toContactWrap}><Link to="/ContactWrap">联系我们</Link> |</li>
                            <li onClick={this.toHelpWrap}><Link to="/HelpWrap">公告中心</Link></li>
                        </ul>
                    </div>
                </div>
                <div className='footer-Authentication m-hide'>
                    <ul>
                        <li style={{ marginRight: '50px', cursor: 'pointer' }}>
                            <em >CGV影城 Copyright © 2016 CJ CGV. All Rights Reserved.  <span onClick={this.toHicp}>沪ICP备 09051306</span></em>
                        </li>
                        <li>
                            <dl onClick={this.toShgs} style={{ marginRight: '1opx' }}>
                                <dt></dt>
                                <dd>
                                    <span>上海工商</span>
                                    <span>行政管理</span>
                                </dd>
                            </dl>
                            <dl onClick={this.toHgawab}>
                                <dt></dt>
                                <dd>
                                    <span>沪公网安备</span>
                                    <span>31011202005678号</span>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                </div>
                <div className="footer-ERcode">
                    <div>
                        <dl>
                            <dt></dt>
                            <dd>下载客户端</dd>
                        </dl>
                        <dl>
                            <dt></dt>
                            <dd>关注CGV影城 公众号</dd>
                        </dl>
                    </div>
                </div>
                <div className='footer-Authentication footer-Authentications'>
                    <ul>
                        <li style={{ marginRight: '50px', cursor: 'pointer' }}>
                            <em>CGV影城 Copyright © 2016 CJ CGV. All Rights Reserved. <span onClick={this.toHicp}>沪ICP备 09051306</span></em>
                        </li>
                        <li>
                            <dl onClick={this.toShgs} style={{ marginRight: '1opx' }}>
                                <dt></dt>
                                <dd>
                                    <span>上海工商</span>
                                    <span>行政管理</span>
                                </dd>
                            </dl>
                            <dl onClick={this.toHgawab}>
                                <dt></dt>
                                <dd>
                                    <span>沪公网安备</span>
                                    <span>31011202005678号</span>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                </div>
            </footer>
        );
    }
}

export default withRouter(Footer);
