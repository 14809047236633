import React from 'react';
import { PageHeader, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import Pic5 from '../../assets/images/Development-pic5.png';
import Pic6 from '../../assets/images/Development-pic6.png';
import Pic1 from '../../assets/images/Development-pic1.png';
import Pic2 from '../../assets/images/Development-pic2.png';
import Pic3 from '../../assets/images/Development-pic3.png';
import Pic4 from '../../assets/images/Development-pic4.png';
import MPic1 from '../../assets/images/m-fzcj-pic/pic1.png';
import MPic2 from '../../assets/images/m-fzcj-pic/pic2.png';
import MPic3 from '../../assets/images/m-fzcj-pic/pic3.png';
import MPic4 from '../../assets/images/m-fzcj-pic/pic4.png';
import MPic5 from '../../assets/images/m-fzcj-pic/pic5.png';
import MPic6 from '../../assets/images/m-fzcj-pic/pic6.png';
import MPicLogo from '../../assets/images/logo.png';
import './DevelopmentWrap.scss';
import HomeIcon from '../../assets/images/home-icon.png';
import { request } from '../../utils/Axios';
const { TabPane } = Tabs;

class DevelopmentWrap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        };
    }
    componentDidMount() {
        this.getList();
    }
    getList = () => {
        request({
            url: '/content/api/homepageMaster/query?type=DEVELOPMENT&city='
        })
            .then((res) => {
                if (res.code === 200) {
                    if (res.data && res.data.length > 0) {
                        this.setState({
                            data: res.data[0]
                        })
                    }
                }
            })
    }
    render() {
        const routes = [
            {
                path: '/',
                breadcrumbName: '首页',
            },
            {
                path: '/',
                breadcrumbName: '发展成就'
            },
        ];
        return (
            <div>
                <div className="titleBar" style={{ padding: '0 13rem' }}>
                    <div className="site-page-header-ghost-wrapper">
                        <img src={HomeIcon} onClick={this.toHome} alt="" className="Home-Icon" />
                        <PageHeader
                            ghost={false}
                            onClick={this.toHome}
                            breadcrumb={{ routes }}
                        >
                        </PageHeader>
                    </div>
                </div>
                <div className="DevelopmentWrap">
                    <Tabs defaultActiveKey="2" animated={false}>
                        <TabPane tab={<div><Link to="/Company">公司介绍</Link></div>} key="1">
                        </TabPane>
                        <TabPane tab={<div><Link to="/DevelopmentWrap">发展成就</Link></div>} key="2">
                        </TabPane>
                        <TabPane tab={<div><Link to="/RecruitmentWrap">诚聘英才</Link></div>} key="3">
                        </TabPane>
                        <TabPane tab={<div><Link to="/ContactWrap">联系我们</Link></div>} key="4">
                        </TabPane>
                    </Tabs>
                    <div className="CompanyWrap-content" dangerouslySetInnerHTML={{ __html: unescape(this.state.data.content) }}>
                    </div>
                </div>
            </div>
        );
    }
}

export default DevelopmentWrap;
