import React from 'react';
import { Carousel } from 'antd';
import { API_HOST_PRD, request, requestPost } from '../../utils/Axios';
import _ from 'lodash';
import { withRouter } from 'react-router';
import Pic from '../../assets/images/sy-banner/sy-banner-content-2.png';
import './carousel.scss';

let API_HOST_PRD_IMG = ''

class BannerWrap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ImgData: []
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        request({
            url: '/party/api/getPropertyGetByPropertyId?resourceId=DOMAIN_IMG_SSL&propertyId=DOMAIN_IMG_SSL',
        })
            .then((res) => {
                API_HOST_PRD_IMG = res.systemPropertyValue
                fetch(API_HOST_PRD + '/content/api/advert/query?channel=HOMEPAGE&advertType=HOMAPAGE_SY_AD&thatCd=', {
                    headers: {
                        'content-type': 'application/json',
                        'Content-Security-Policy': ' upgrade-insecure-requests'
                    },
                    method: 'GET',
                    mode: 'cors'
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((res) => {
                        console.log(res)
                        if (res.code === 200) {
                            this.setState({
                                ImgData: res.data
                            });
                        }
                    });
            });

    };
    toActivityDetails(url) {
        if (url === 'ActivityWarp') {
            this.props.history.push(url);
        } else {
            window.location.href = decodeURIComponent(url);
        }
    };

    render() {
        const { ImgData } = this.state;
        return (
            <div className='bannerBox'>
                <Carousel autoplay={true}>
                    {
                        ImgData.length !== 0 ?
                            _.map(ImgData, (item, index) => {
                                return (
                                    <div key={index} className="banner-content"
                                        onClick={(url) => this.toActivityDetails(item.advertImgLinkurl)}>
                                        <img src={API_HOST_PRD_IMG + item.advertImg} alt='' />
                                    </div>
                                );
                            })
                            : ''
                    }
                </Carousel>
            </div>
        );
    }
}

export default withRouter(BannerWrap);
