import React from "react";
import { PageHeader, Tabs } from 'antd';
import { Link } from "react-router-dom";
import Pic from "../../assets/images/gs-info-pic.png";
import HomeIcon from '../../assets/images/home-icon.png';
import './Compant.scss';
import { request } from '../../utils/Axios';

const { TabPane } = Tabs
class Company extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyData: {},
        }
    }
    componentDidMount() {
        this.getList();
    }
    getList = () => {
        request({
            url: '/content/api/homepageMaster/query?type=COMPANYPROFILE&city='
        })
            .then((res) => {
                if (res.code === 200) {
                    if (res.data && res.data.length > 0) {
                        this.setState({
                            companyData: res.data[0]
                        })
                    }
                    console.log(this.state)
                }
            })
    }
    render() {
        const routes = [
            {
                path: '/',
                breadcrumbName: '首页',
            },
            {
                path: '/',
                breadcrumbName: '公司介绍'
            },
        ];
        return (
            <div>
                <div className="titleBar" style={{ padding: '0 13rem' }}>
                    <div className="site-page-header-ghost-wrapper">
                        <img src={HomeIcon} onClick={this.toHome} alt="" className="Home-Icon" />
                        <PageHeader
                            ghost={false}
                            onClick={this.toHome}
                            breadcrumb={{ routes }}
                        >
                        </PageHeader>
                    </div>
                </div>
                <div className="Company-wrap">
                    <Tabs defaultActiveKey="1" animated={false}>
                        <TabPane tab={<div><Link to="/Company">公司介绍</Link></div>} key="1">
                        </TabPane>
                        <TabPane tab={<div><Link to="/DevelopmentWrap">发展成就</Link></div>} key="2">
                        </TabPane>
                        <TabPane tab={<div><Link to="/RecruitmentWrap">诚聘英才</Link></div>} key="3">
                        </TabPane>
                        <TabPane tab={<div><Link to="/ContactWrap">联系我们</Link></div>} key="4">
                        </TabPane>
                    </Tabs>
                    <div className="CompanyWrap-content" dangerouslySetInnerHTML={{ __html: unescape(this.state.companyData.content) }}>
                    </div>
                </div>
            </div>
        );
    }
}

export default Company
