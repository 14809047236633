import React from 'react';
import _ from 'lodash';
import { request, requestPost } from '../../utils/Axios';
import { Tabs } from 'antd';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import MBG from '../../assets/images/dy-mr-bg.jpeg';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import JySlider from './jySlider';
import './TabsConpoent.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const { TabPane } = Tabs;
let API_HOST_PRD_IMG = ''

class TabsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            countL: 5,
            countR: 5,
            DataL: [], // 在售电影
            DataR: [], // 即映电影,
        };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    componentDidMount() {
        // 获取电影数据
        this.getData();
        this.getDataR();
    }


    // 在售影片 取数据
    getData = (prCity) => {
        request({
            url: '/party/api/getPropertyGetByPropertyId?resourceId=DOMAIN_IMG_SSL&propertyId=DOMAIN_IMG_SSL',
        })
            .then((res) => {
                API_HOST_PRD_IMG = res.systemPropertyValue
                request({
                    url: '/product/plans?prCity=226&chnlNo=05'
                })
                    .then((res) => {
                        if (res.code === 200) {
                            let v = res.data.datas
                            console.log(v)
                            this.setState({
                                DataL: v
                            });
                        }
                    });
            });
    };
    // 即映影片 取数据
    getDataR = () => {
        requestPost({
            url: '/product/movie/list-soon',
            data: {
                productTypeCd: '3',
                sortField: {
                    fieldName: 'releaseDate',
                    sortOrder: 'asc',
                },
                cityCd: 226,
                chnlNo: '05',
            }
        })
            .then((res) => {
                if (res.code === 200) {
                    if (res.data.content.length < 3) {
                        this.setState({
                            countR: 1
                        });
                    }
                    this.setState({
                        DataR: res.data.content.slice(0, 8)
                    });
                }
            });
    };
    // 路由跳转
    ToFilmWrapLP = (id) => {
        const { history } = this.props;
        history.push('/FilmWrap/FilmDetails');
        sessionStorage.setItem('FilmDetailsId', id);
        sessionStorage.setItem('FilmDetailsCode', '1');
    };
    ToFilmWrapLM = (id) => {
        const { history } = this.props;
        history.push('/FilmWrap/FilmDetails');
        sessionStorage.setItem('FilmDetailsId', id);
        sessionStorage.setItem('FilmDetailsCode', '1');
    };
    ToFilmWrapRP = (id) => {
        const { history } = this.props;
        history.push('/FilmWrap/FilmDetails');
        sessionStorage.setItem('FilmDetailsId', id);
        sessionStorage.setItem('FilmDetailsCode', '1');
    };
    ToFilmWrapRM = (id) => {
        const { history } = this.props;
        history.push('/FilmWrap/FilmDetails');
        sessionStorage.setItem('FilmDetailsId', id);
        sessionStorage.setItem('FilmDetailsCode', '1');
    };
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {
        let settings = {};
        if (window.screen.width < 450) {
            settings = {
                dots: false,
                infinite: false,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 3,
                center: false
            };
        } else {
            settings = {
                dots: false,
                infinite: false,
                speed: 500,
                slidesToShow: 5,
                slidesToScroll: 5,
                center: false
            };
        }
        const operations =
            <div
                className="moreBtn"
                style={{
                    marginRight: '10px',
                    fontSize: '16px'
                }}
            >
                <Link to="/FilmWrap">更多电影 <RightOutlined /></Link>
            </div>;
        const { countL, DataL, DataR } = this.state;
        return (
            <div className='TabsBos'>
                <Tabs
                    defaultActiveKey="1"
                    animated={false}
                    tabBarExtraContent={operations}
                    tabBarUnderlineStyle={{ background: '#fc5869' }}
                >
                    <TabPane tab="在售影片" key="1">
                        <div style={{ position: 'relative' }}>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                                {
                                    _.map(DataL, (item, index) => {
                                        return (
                                            <div key={index}>
                                                <div className="dy-img-box">
                                                    <div className="img-box">
                                                        <div className="DY-img-show" onClick={this.ToFilmWrapLP.bind(this, item.movId)}>
                                                            {
                                                                item.imgUrl && item.imgUrl || item.imgUrl !== null ?
                                                                    <img src={API_HOST_PRD_IMG + item.imgUrl}
                                                                        alt=""
                                                                    />
                                                                    : <img src={MBG} alt='' />
                                                            }
                                                        </div>
                                                        <div className="DY-text-show">
                                                            <div className="DY-texts-tips">
                                                                <p>{item.description}</p>
                                                            </div>
                                                            <div className="bottom-box">
                                                                <b onClick={this.ToFilmWrapLP.bind(this, item.movId)}>查看详情</b>
                                                                <div className="DY-texts">
                                                                    电影评分：<em>{item.productRating}</em>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dy-name">{item.productName}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>

                            <span className="slider-btn left-slider-btn" onClick={this.previous}><LeftOutlined style={{ color: '#fff' }} /></span>
                            <span className="slider-btn right-slider-btn" onClick={this.next}><RightOutlined style={{ color: '#fff' }} /></span>

                        </div>
                    </TabPane>
                    <TabPane tab="即映影片" key="2">
                        <JySlider data={[DataR, API_HOST_PRD_IMG]}></JySlider>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

export default withRouter(TabsComponent);
