import React from "react";
import moment from 'moment';
import _ from 'lodash';
import Axios from 'axios';
import { Tabs, Modal, PageHeader, Popover, Empty } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { request, API_HOST_PRD } from '../../utils/Axios';
import HomeIcon from '../../assets/images/home-icon.png';
import './RecruitmentWrap.scss';
const { TabPane } = Tabs;

class RecruitmentWrap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataLList: [],
            visible: false,
            ModalData: [],
            cityData: [], //城市数据
            cityCode: [],
            curCityCHNames: sessionStorage.getItem('curCityCHName'),
            cityVisible: false,
            cityList: []
        }
    }

    componentWillMount() {
        this.getCityData();
        this.getCityList()
    }
    getList = (code) => {
        request({
            url: '/content/api/homepageMaster/query?type=RECRUIT&city=' + code
        })
            .then((res) => {
                if (res.code === 200) {
                    this.setState({
                        dataLList: res.data
                    })

                }
            })
    }
    getCityList = () => {
        request({
            url: '/product/areas'
        })
            .then((res) => {
                this.setState({
                    cityList: res
                })
            })
    }
    showModal = (data) => {
        this.setState({
            visible: true,
            ModalData: data
        });
    };
    // 取城市数据
    getCityData = () => {
        Axios.get(API_HOST_PRD + '/product/areas/group')
            .then((res) => {
                if (res.status === 200) {
                    this.setState({
                        cityData: res.data
                    })
                    _.map(res.data, (C) => {
                        _.map(C.data, (data, index) => {
                            if (data.CITY_NAME === sessionStorage.getItem('curCityCHName')) {
                                this.getList(data.CITY_CD)
                            }
                        })
                    })
                }
            })
    };
    //设置城市visible
    setcityVisible = () => {
        this.setState({
            cityVisible: true
        })
    }
    //选择城市
    curCityCHNames = (city) => {
        this.getList(city.CITY_CD)
        this.setState({
            curCityCHNames: city.CITY_NAME,
            cityVisible: false,
        })
        sessionStorage.setItem('curCityCHName', city.CITY_NAME)
    }
    getCityName = (item) => {
        console.log(this.state.cityList)

        let arr = []
        if (item) {
            arr = item.city ? item.city.split(',') : []
        } else {
            arr = this.state.ModalData.city ? this.state.ModalData.city.split(',') : []
        }
        let list = this.state.cityList.filter(c => {
            console.log(arr.indexOf(c.CITY_CD), '----------')
            return arr.indexOf(c.CITY_CD) != -1
        })
        let names = []
        list.forEach(item => {
            names.push(item['CITY_NAME'])
        })

        console.log(list, arr, names)
        return names.join(',')
    }
    toCity = () => {
        this.props.history.push('/McityWrap')
    }
    render() {
        const {
            ModalData,
            dataLList,
            cityData,
            curCityCHNames,
            cityVisible,
            cityList
        } = this.state;
        const routes = [
            {
                path: '/',
                breadcrumbName: '首页',
            },
            {
                path: '/RecruitmentWrap',
                breadcrumbName: '诚聘英才'
            },
        ];
        const CityWrap = (
            <div className="City-content">
                {
                    _.map(cityData, (item, index) => {
                        return (
                            <div style={{ overflowY: 'scroll' }} key={index}>
                                <div className="City-content-name">
                                    <p key={index}>{item.name}</p>
                                    {
                                        _.map(item.data, (item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="City-content-names"
                                                    onClick={this.curCityCHNames.bind(this, item)}
                                                >
                                                    {item.CITY_NAME}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
        return (
            <div>
                <div className="titleBar" style={{ padding: '0 13rem' }}>
                    <div className="site-page-header-ghost-wrapper">
                        <img src={HomeIcon} onClick={this.toHome} alt="" className="Home-Icon" />
                        <PageHeader
                            ghost={false}
                            onClick={this.toHome}
                            extra={[
                                <div className="CityWrap">
                                    <div className="CityWrap-P">
                                        <Popover
                                            placement="bottomRight"
                                            style={{ top: '120px' }}
                                            content={CityWrap}
                                            trigger="click"
                                            visible={cityVisible}
                                        >
                                            <div
                                                style={{ marginRight: '10px' }}
                                                onClick={this.setcityVisible}
                                            >
                                                {curCityCHNames}
                                                <CaretDownOutlined />
                                            </div>
                                        </Popover>
                                    </div>
                                    <div className="CityWrap-M">
                                        <div onClick={this.toCity}>{sessionStorage.getItem('curCityCHName')}</div>
                                        <CaretDownOutlined onClick={this.toCity} />
                                    </div>
                                </div>
                            ]}
                            breadcrumb={{ routes }}
                        >
                        </PageHeader>
                    </div>
                </div>

                <div className="RecruitmentWrap">
                    <Tabs defaultActiveKey="3" animated={false}>
                        <TabPane tab={<div><Link to="/Company">公司介绍</Link></div>} key="1">
                        </TabPane>
                        <TabPane tab={<div><Link to="/DevelopmentWrap">发展成就</Link></div>} key="2">
                        </TabPane>
                        <TabPane tab={<div><Link to="/RecruitmentWrap">诚聘英才</Link></div>} key="3">
                        </TabPane>
                        <TabPane tab={<div><Link to="/ContactWrap">联系我们</Link></div>} key="4">
                        </TabPane>
                    </Tabs>
                    <div>
                        {/* <div className="Recruitment-top">
                            <p>诚聘英才</p>
                            <span>欢迎充满热情与责任感、德才兼备的你</span>
                        </div> */}
                        <div className="Recruitment-list">
                            <ul>
                                {
                                    dataLList.length > 0 ? _.map(dataLList, (item, index) => {
                                        return (
                                            <li key={index} onClick={this.showModal.bind(this, item)}>
                                                <span>
                                                    {/* <em>{this.getCityName(item)}</em> */}
                                                    {/* {item.content} */}
                                                    <span className="CompanyWrap-content" dangerouslySetInnerHTML={{ __html: unescape(item.content) }}>
                                                    </span>
                                                </span>
                                                <span>{item.starttime ? moment(item.starttime).format('YYYY-MM-DD') : ''}</span>
                                            </li>
                                        )
                                    })
                                        :
                                        <Empty style={{ marginTop: 50 }} description="暂无数据" />
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <Modal
                    maskClosable={false}
                    title={
                        <div className="RecruitmentWrap-M-Title">
                            <ul style={{
                                display: 'flow-root',
                                padding: 0,
                                paddingTop: '35px',
                            }}>
                                <li>
                                    <span style={{
                                        fontWeight: 'normal',
                                    }}>
                                        {/* <em style={{
                                            fontStyle: 'normal',
                                            background: '#F1A23D',
                                            borderRadius: '18px',
                                            color: 'white',
                                            padding: '2px 10px',
                                            whiteSpace: 'nowrap',
                                            marginRight: 10
                                        }}>
                                            {
                                                this.getCityName()
                                            }
                                        </em> */}
                                        <span style={{ color: '#000' }}>{ModalData.title}</span>
                                    </span>
                                </li>
                                <li>
                                    <span style={{
                                        fontWeight: 'normal',
                                        color: '#777777',
                                        marginTop: '10px',
                                        display: 'block',
                                        paddingLeft: '60px',
                                    }}>{ModalData.starttime ? moment(ModalData.starttime).format('YYYY-MM-DD') : ''}</span>
                                </li>
                            </ul>
                        </div>
                    }
                    visible={this.state.visible}
                    onOk={() => { this.setState({ visible: false }) }}
                    onCancel={() => { this.setState({ visible: false }) }}
                    footer={<div />}
                >
                    {/* <p>{ModalData.content}</p> */}
                    <p className="CompanyWrap-content" dangerouslySetInnerHTML={{ __html: unescape(ModalData.content) }}>
                    </p>
                    <p>
                        <img src={ModalData.linkObjectInfo} alt='' />
                    </p>
                    <p>{ModalData.textData}</p>
                </Modal>
            </div>
        );
    }
}

export default RecruitmentWrap;
