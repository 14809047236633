
//测试
import React from 'react';
import { withRouter } from "react-router";
import OnsaleWrap from '../Onsale';

class FilmDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const code = sessionStorage.getItem('FilmDetailsCode')
        return (
            <div>
                {
                    code === '1'
                        ?
                        // 电影列表 - 在售影片
                        <OnsaleWrap />
                        :
                        // 电影列表 - 即映影片
                        <OnsaleWrap />
                }
            </div>
        );
    }
}
export default withRouter(FilmDetails);
