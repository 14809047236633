import React from "react";
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import Pic1 from '../../assets/images/card1.png';
import { RightOutlined, } from '@ant-design/icons';
import { withRouter } from 'react-router';
import { request } from '../../utils/Axios';
import './CardContent.scss';

let API_HOST_PRD_IMG = ''

class CardContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            CarData: [],
            testData: [
                { img: Pic1, name: '摇一摇', time: '2020-01-01 ~ 2020-10-10' },
            ],
        };
    }
    componentDidMount() {
        //获取数据
        this.getData()
    }
    getData = () => {
        request({
            url: '/party/api/getPropertyGetByPropertyId?resourceId=DOMAIN_IMG_SSL&propertyId=DOMAIN_IMG_SSL',
        })
            .then((res) => {
                API_HOST_PRD_IMG = res.systemPropertyValue
                request({
                    url: '/product/api/product/promos?channel=HOMEPAGE',
                })
                    .then((res) => {
                        if (res.code === 200) {
                            this.setState({
                                CarData: res.data.splice(0, 4)
                            })
                        }
                    })
            });
    }
    toActivity = (data) => {
        let fromDate = data.productPromoConds.filter(i => i.inputParam == 'FROM_DATE')[0].condValue
        let thurDate = data.productPromoConds.filter(i => i.inputParam == 'THRU_DATE')[0].condValue
        let posterImg = data.dataResourceModels.filter(i => i.contentTypeCd == 'PROMO_POSTER_IMG')[0] ? data.dataResourceModels.filter(i => i.contentTypeCd == 'PROMO_POSTER_IMG')[0].objectInfo : ''
        // 存活动详情id
        sessionStorage.setItem('ActivityDetailsID', data.id);
        sessionStorage.setItem('fromDate', fromDate);
        sessionStorage.setItem('thurDate', thurDate);
        sessionStorage.setItem('posterImg', posterImg);
        sessionStorage.setItem('userLevelLimit', data.userLevelLimit);

        this.props.history.push('/ActivityWarp/ActivitDetails')
        sessionStorage.setItem('ActivityDetailsID', data.id);
    }
    render() {
        const { CarData, testData } = this.state;
        return (
            <div className="site-card-wrapper">
                <div className="site-card-wrapper-title">
                    <h6>CGV活动</h6>
                    <span style={{ marginRight: '10px' }}><Link to="/ActivityWarp">更多活动<RightOutlined /></Link></span>
                </div>
                <ul className="card-m">
                    {
                        CarData.length !== 0 ? _.map(CarData, (item, index) => {
                            return (
                                <li key={index} onClick={this.toActivity.bind(this, item)}>
                                    <div className="site-card-wrapper-slid">
                                        {
                                            item.dataResourceModels.filter(i => i.contentTypeCd == 'PROMO_POSTER_MIN_IMG')[0].objectInfo && item.dataResourceModels.filter(i => i.contentTypeCd == 'PROMO_POSTER_MIN_IMG')[0].objectInfo ?
                                                <img src={API_HOST_PRD_IMG + item.dataResourceModels.filter(i => i.contentTypeCd == 'PROMO_POSTER_MIN_IMG')[0].objectInfo} alt='' />
                                                :
                                                <img src={testData[0].img} alt='' />

                                        }
                                        <em>{item.promoName}</em>
                                        <div className="time">
                                            <div>
                                                {moment(item.fromDate).format('YYYY-MM-DD')}
                                                ~   {moment(item.thruDate).format('YYYY-MM-DD')}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                            : ''
                    }
                </ul>
            </div>
        );
    }
}

export default withRouter(CardContent);
