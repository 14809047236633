export const API_HOST_DEV = 'http://dev-api.cgv.com.cn';
export const API_HOST_STG = 'http://prd-api.cgv.com.cn';
export const API_HOST_PRD = 'https://prd-api.cgv.com.cn';
export const API_HOST_PRD_IMG = 'http://prd-api.cgv.com.cn/api';
export const API_HOST_STG_IMG = 'http://prd-api.cgv.com.cn/api';
// export const API_HOST_PRD_IMG_ = 'http://img.prd-web.cgv.com.cn';

//解析json
function parseJSON(response) {
    return response.json();
}

//检查请求状态
function checkStatus(response) {
    if (response.status >= 200 && response.status < 500) {
        return response;
    }
    if (response.status === 500) {
        return response;
    }
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
}

export function request(options = {}) {
    const { data, url } = options;
    options = { ...options };
    delete options.url;
    if (data) {
        delete options.data;
        options.body = JSON.stringify({
            data
        });
    }
    options.headers = {
        'Content-Type': 'application/json'
        // 'Content-Security-Policy': ' upgrade-insecure-requests'
    };
    return fetch(API_HOST_PRD + url, options)
        .then(checkStatus)
        .then(parseJSON)
        .catch(err => ({ err }));
}


export function requestPost(options = {}) {
    const { url, data } = options;
    options = { ...options };
    options.method = 'POST';
    options.body = JSON.stringify({
        ...data
    });
    options.headers = {
        'Content-Type': 'application/json'
        // 'Content-Security-Policy': ' upgrade-insecure-requests'
    };
    return fetch(API_HOST_PRD + url, options)
        .then(checkStatus)
        .then(parseJSON)
        .catch(err => ({ err }));
}
