import React from 'react';
import BannerWrap from "../BannerWrap";
import CardContent from "../CardContent";
import Notice from "../Notice";
import TabsComponent from "../TabsComponent";
import Technological from "../Technological";
import Introduction from "../Introduction";
import './Home.scss';

class Home extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="App">
                <div className="mainContent">
                    {/*banner*/}
                    <BannerWrap />
                    {/*公告*/}
                    <Notice />
                    {/*电影*/}
                    <TabsComponent />
                    {/*活动*/}
                    <CardContent />
                    {/*CGV影院简介*/}
                    <Introduction />
                    {/*科技革新*/}
                    <Technological />
                </div>
            </div>
        );
    }
}
export default Home;
