import React from 'react';
import { request } from '../../../utils/Axios';
import Pic from '../../../assets/images/card1.png';
import Pic1 from '../../../assets/images/xin-pic.png';
import Pic2 from '../../../assets/images/xin-pic2.png';
import Pic3 from '../../../assets/images/xin-pic3.png';
import HomeIcon from '../../../assets/images/home-icon.png';
import { PageHeader } from 'antd';
import './ActivityDetails.scss';
import Icon1 from '../../../assets/images/HD-Icon-x/icon1-2.png';
import Icon2 from '../../../assets/images/HD-Icon-x/icon2-2.png';
import Icon3 from '../../../assets/images/HD-Icon-x/icon3-2.png';
import Icon4 from '../../../assets/images/HD-Icon-x/icon4-2.png';

let API_HOST_PRD_IMG = ''

class ActivityDetatils extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DetailsList: [],
            fromDate: sessionStorage.getItem('fromDate'),
            thruDate: sessionStorage.getItem('thurDate'),
            posterImg: sessionStorage.getItem('posterImg'),
            userLevelLimit: sessionStorage.getItem('userLevelLimit')
        };
    }

    componentDidMount() {
        this.getList();
    }

    getList = () => {
        const id = sessionStorage.getItem('ActivityDetailsID');
        request({
            url: '/party/api/getPropertyGetByPropertyId?resourceId=DOMAIN_IMG_SSL&propertyId=DOMAIN_IMG_SSL',
        })
            .then((res) => {
                API_HOST_PRD_IMG = res.systemPropertyValue
                request({
                    url: '/product/api/product-promos/' + id
                })
                    .then((res) => {
                        this.setState({
                            DetailsList: res
                        })
                    })
            });
    };

    render() {
        const { DetailsList, posterImg, fromDate, thruDate, userLevelLimit } = this.state;
        console.log(userLevelLimit)
        const routes = [
            {
                path: '/',
                breadcrumbName: '首页',
            },
            {
                path: '/ActivityWarp',
                breadcrumbName: '活动'
            },
            {
                path: '/ActivityWarp/ActivitDetails',
                breadcrumbName: '活动详情'
            },
        ];
        return (
            <div>
                <div className="titleBar" style={{ padding: '0 13rem' }}>
                    <div className="site-page-header-ghost-wrapper">
                        <img src={HomeIcon} onClick={this.toHome} alt="" className="Home-Icon" />
                        <PageHeader
                            ghost={false}
                            onClick={this.toHome}
                            breadcrumb={{ routes }}
                        >
                        </PageHeader>
                    </div>
                </div>
                <div className="Activity-Details-wrap">
                    <h6>活动详情</h6>
                    <div className="Activity-content">
                        <div>
                            <p>{DetailsList.promoName}</p>
                            <p>
                                <span>活动期限：
                                    {fromDate && fromDate.split('T')[0]} ~
                                    {thruDate && thruDate.split('T')[0]}
                                </span>
                                <span>
                                    {
                                        userLevelLimit ?
                                            <div>
                                                {
                                                    userLevelLimit.split(',').filter(u => u == '01').length > 0 ?
                                                        <em>
                                                            <img src={Icon1} alt='' />
                                                                                初心
                                                                            </em> : null
                                                }
                                                {
                                                    userLevelLimit.split(',').filter(u => u == '02').length > 0 ?
                                                        <em>
                                                            <img src={Icon2} alt="" />
                                                                         动心
                                                                         </em> : null
                                                }
                                                {
                                                    userLevelLimit.split(',').filter(u => u == '03').length > 0 ?
                                                        <em>
                                                            <img src={Icon3} alt="" />
                                                                        知心</em> : null
                                                }
                                                {
                                                    userLevelLimit.split(',').filter(u => u == '04').length > 0 ?
                                                        <em>
                                                            <img src={Icon4} alt="" />衷心</em> : null
                                                }
                                            </div>
                                            : null

                                    }
                                </span>
                            </p>
                        </div>
                        <div className="Activity-content-pic">
                            {/* {DetailsList} */}
                            <img src={API_HOST_PRD_IMG + posterImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ActivityDetatils;
