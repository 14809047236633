import React from 'react';
import { PageHeader, Rate } from 'antd';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { request } from '../../../utils/Axios';
import HomeIcon from '../../../assets/images/home-icon.png';
import Zmage from 'react-zmage';  // 缩放
import SwiperAPP from './FilmSwiper';
import Swiper from 'swiper';
import Pic from '../../../assets/images/DY-Pic/Pic1.png';
import Pic2 from '../../../assets/images/DY-Pic/Pic2.png';
import Pic3 from '../../../assets/images/DY-Pic/Pic3.png';
import Pic4 from '../../../assets/images/DY-Pic/Pic4.png';

import Icon1 from '../../../assets/images/dyxq-pic-icon1.png';
import Icon2 from '../../../assets/images/dyxq-pic-icon2.png';
import Icon3 from '../../../assets/images/dyxq-pic-icon3.png';
import Icon4 from '../../../assets/images/dyxq-pic-icon4.png';
import 'swiper/dist/css/swiper.min.css';
import './Onsale.scss';
import Axios from 'axios';

let API_HOST_PRD_IMG = ''

class OnsaleWrap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DetailsData: [], //详情数据
            DataListV: [], //剧照
            data: [
                { img: Pic },
                { img: Pic2 },
                { img: Pic3 },
                { img: Pic4 }
            ],
            curImgData: '',
            MCount: 5,
            chnlNo: '',
        };
    }

    componentDidMount() {
        //获取数据
        this.getData();
        //取电影CD
        this.getCityData();
        if (window.innerWidth < 750) {
            this.setState({
                MCount: 2
            });
        } else {
            this.setState({
                MCount: 5
            });
        }
        //    init swiper
        setTimeout(() => {
            new Swiper('.swiper-container', {
                slidesPerView: 5,
                spaceBetween: 12,
                navigation: {//左右分页
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                observer: true,
                // observeParents: true,
                freeMode: true,
            });
        }, 0)
    }

    //取城市cd
    getCityData = () => {
        request({
            url: '/product/areas/group'
        })
            .then((res) => {
                this.setState({
                    cityData: res
                });
                _.map(res, (item) => {
                    _.map(item.data, (items) => {
                        if (items.CITY_NAME === sessionStorage.getItem('curCityCHName')) {

                            sessionStorage.setItem('curCityId', items.CITY_CD)
                            this.setState({
                                cityCd: items.CITY_CD,
                            })
                            console.log(items.CITY_CD)
                            this.getData(items.CITY_CD)
                        }
                    })
                })
            })
    };

    getData = (CD) => {
        const curId = sessionStorage.getItem('FilmDetailsId');
        request({
            url: '/party/api/getPropertyGetByPropertyId?resourceId=DOMAIN_IMG_SSL&propertyId=DOMAIN_IMG_SSL',
        })
            .then((res) => {
                API_HOST_PRD_IMG = res.systemPropertyValue
                request({
                    url: '/product/movie/' + curId + '?cityCd=' + CD + '&chnlNo=' + '07'
                })
                    .then((res) => {
                        if (res.code === 200) {
                            this.setState({
                                DetailsData: res.data.product
                            });
                            console.log(res.data.product)
                        }
                    });
            });
    };
    toCurImg = (curImg) => {
        this.setState({
            curImgData: curImg.objectInfo
        });
    };
    //猫链接
    scrollToAnchor = (anchorName) => {
        document.querySelector('#Detail-Img').scrollIntoView({
            block: 'start',
            behavior: 'smooth'
        });
    };

    render() {
        const { DetailsData, DataListV, curImgData } = this.state;
        const routes = [
            {
                path: '/',
                breadcrumbName: '首页'
            },
            {
                path: '/FilmWrap',
                breadcrumbName: '电影'
            }, {
                path: '/FilmWrap/FilmDetails',
                breadcrumbName: '电影详情'
            }
        ];
        const bgImg = API_HOST_PRD_IMG + DetailsData.detailImageUrl;
        const numLink = (
            <i className="colorR">{DetailsData.likeCnt}</i>
        )
        return (
            <div className="Acti-wrap">
                <div className="Detail-bg"
                >
                    <div className="vidoeMark"
                        style={{
                            background: `url(${bgImg})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundOrigin: '30px 50px'
                        }}
                    >
                        <div className="mark" />
                    </div>
                    <dl>
                        <dt>
                            <img
                                src={API_HOST_PRD_IMG + DetailsData.smallImageUrl}
                                alt=''
                                style={{
                                    borderRadius: '5px', width: '100%'
                                }}
                            />
                        </dt>
                        <dd className="detail-right-box">
                            <div className="product-name"> {DetailsData.productName}</div>
                            {
                                DetailsData.productRating !== null ?
                                    <div className="row-two-box">
                                        <span className="yellow-text">{DetailsData.productRating}</span>
                                        <Rate disabled style={{ fontSize: '16px', margin: '0 10px' }} value={(DetailsData.productRating / 2)} />
                                        <span className="white-line">|</span>
                                        <span className="yellow-text">
                                            {DetailsData.likeCnt}
                                        </span>
                                        <span className="white-text"> 人想看</span>
                                    </div>
                                    : null
                            }
                            {
                                _.map(DetailsData.movTypFmt, (item, ind) => {
                                    return (
                                        <span className="move-type" key={ind}>
                                            <span className="type-left">{_.split(item, '-')[0]}</span>
                                            <span className="type-right">{_.split(item, '-')[1]}</span>
                                        </span>
                                    );
                                })
                            }
                            {
                                DetailsData.description ? <div className="row-three-box">
                                    <span className="desc" >{DetailsData.description}</span>
                                </div> : null
                            }

                            <div className="row-four-box">
                                <span>
                                    <img src={Icon1} alt='' />&nbsp;
                                    {
                                        _.map(DetailsData.productAttributes, (item, index) => {
                                            if (item.attrName === 'scn_tm') {
                                                return (
                                                    <span>{item.attrValue}</span>
                                                );
                                            }
                                        })
                                    }
                                        分钟
                                </span>
                                <span >
                                    <img src={Icon2} alt='' />&nbsp;
                                    {
                                        new Date(
                                            +new Date(new Date(DetailsData.releaseDate).toJSON()) +
                                            8 * 3600 * 1000)
                                            .toISOString()
                                            .split('T')[0]
                                    }
                                </span>
                            </div>
                            <div className="row-four-box">
                                <span>
                                    <img src={Icon3} alt='' />&nbsp;
                                    {
                                        _.map(DetailsData.productAttributes, (item, index) => {
                                            if (item.attrName === 'mov_cpt_lag_cd') {
                                                return (
                                                    <span>{item.attrValue}</span>
                                                );
                                            }
                                        })
                                    }
                                </span>

                                <span >
                                    <img src={Icon4} alt='' />&nbsp;
                                    {
                                        _.map(DetailsData.productAttributes, (item, index) => {
                                            if (item.attrName === 'ctry_fg_cd') {
                                                return (
                                                    <span>{item.attrValue}</span>
                                                );
                                            }
                                        })
                                    }
                                </span>
                            </div>
                        </dd>
                    </dl>
                </div>
                <div className="titleBar m-titlBar"
                    style={{
                        padding: '0 13rem',
                        position: 'relative',
                        zIndex: '9999999',
                        width: '100%'
                    }}>
                    <div className="site-page-header-ghost-wrapper">
                        <img src={HomeIcon} style={{ marginRight: 8 }} onClick={this.toHome} alt="" className="Home-Icon" />
                        <PageHeader
                            ghost={false}
                            onClick={this.toHome}
                            breadcrumb={{ routes }}
                        >
                        </PageHeader>
                    </div>
                </div>
                <div className="ActivityWarp">
                    <div className="ActivityWarp-title">
                        <a>简介</a>
                        <span>|</span>
                        <a onClick={() => this.scrollToAnchor('screens2')}>剧照</a>
                    </div>
                    <div className="Detail-Text">
                        <ul>
                            <li><div>电影简介</div></li>
                            <li><span>导演：</span>
                                {
                                    _.map(DetailsData.productAttributes, (item, index) => {
                                        if (item.attrName === 'director') {
                                            return (
                                                <em>{item.attrValue}</em>
                                            );
                                        }
                                    })
                                }
                            </li>
                            <li><span>主演：</span>
                                {
                                    _.map(DetailsData.productAttributes, (item, index) => {
                                        if (item.attrName === 'actor') {
                                            return (
                                                <em>{item.attrValue}</em>
                                            );
                                        }
                                    })
                                }
                            </li>
                            <li>
                                <span>
                                    {
                                        DetailsData && DetailsData ? DetailsData.longDescription : ''
                                    }
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="Detail-Img" id='Detail-Img'>
                        <h6 id="剧照">剧照</h6>
                        <SwiperAPP data={
                            (DetailsData.originalImageUrl ? DetailsData.originalImageUrl.split(',') : []).concat(DetailsData.stageImageUrl ? DetailsData.stageImageUrl.split(',') : [])
                        } />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(OnsaleWrap);
