import React from 'react';
import _ from 'lodash';
import $ from 'jquery';
import { PageHeader, Select, Popover, Button } from 'antd';
import {
    CaretDownOutlined
} from '@ant-design/icons';
import { requestPost, request } from '../../utils/Axios';
import IMG from '../../assets/images/card1.png';
import Icon1 from '../../assets/images/HD-Icon-x/icon1-2.png';
import Icon2 from '../../assets/images/HD-Icon-x/icon2-2.png';
import Icon3 from '../../assets/images/HD-Icon-x/icon3-2.png';
import Icon4 from '../../assets/images/HD-Icon-x/icon4-2.png';
import Icon from '../../assets/images/HD-icon/HD-icon1-2.png';
import Downicon from '../../assets/images/down-icon.png';
import './ActivityWarp.scss';
import HomeIcon from '../../assets/images/home-icon.png';
import Axios from 'axios';

const { Option } = Select;
let API_HOST_PRD_IMG = ''

class ActivityWarp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            testData: [
                { img: IMG, title: '摇一摇', time: '2020-01-10 ~ 2020-10-10' }
            ],
            curCount: 12,
            listType: [],
            colorValue: ['1'], //当前活动类型
            TypeName: '全部活动',
            TypeVisible: false
        };
    }

    componentDidMount() {
        sessionStorage.removeItem('curPage');
        // 取活动列表
        this.getList();
        //    获取活动类型
        this.getActivityType();
    }
    getImgUrl = () => {

    }
    // 活动列表
    getList = () => {
        request({
            url: '/party/api/getPropertyGetByPropertyId?resourceId=DOMAIN_IMG_SSL&propertyId=DOMAIN_IMG_SSL',
        })
            .then((res) => {
                API_HOST_PRD_IMG = res.systemPropertyValue
                request({
                    url: '/product/api/product/promos?channel=HOMEPAGE'
                })
                    .then((res) => {
                        if (res.code === 200) {
                            res.data.sort(function (a, b) {
                                return new Date(b.thruDate).getTime() < new Date(a.thruDate).getTime() ? -1 : 1
                            })
                            res.data.forEach(r => {
                                if (new Date(r.thruDate).getTime() < new Date().getTime()) {
                                    r['overdue'] = true
                                } else {
                                    r['overdue'] = false
                                }
                            })
                            this.setState({
                                list: res.data
                            });
                        }
                    });
            });
    };
    //获取活动类型
    getActivityType = () => {
        request({
            url: '/product/api/product/promo/categories'
        })
            .then((res) => {
                if (res.code === 200) {
                    this.setState({
                        listType: res.data
                    });
                }
            });
    };
    //选择活动类型
    setActivityType = (value) => {
        request({
            url: '/product/api/product/promos?channel=HOMEPAGE&andGroupCd=' + value
        })
            .then((res) => {
                if (res.code === 200) {
                    res.data.sort(function (a, b) {
                        return new Date(b.thruDate).getTime() < new Date(a.thruDate).getTime() ? -1 : 1
                    })
                    res.data.forEach(r => {
                        if (new Date(r.thruDate).getTime() < new Date().getTime()) {
                            r['overdue'] = true
                        } else {
                            r['overdue'] = false
                        }
                    })
                    this.setState({
                        list: res.data
                    });
                }
            });
    };

    toCurActive = (data) => {
        let fromDate = data.productPromoConds.filter(i => i.inputParam == 'FROM_DATE')[0].condValue
        let thurDate = data.productPromoConds.filter(i => i.inputParam == 'THRU_DATE')[0].condValue
        let posterImg = data.dataResourceModels.filter(i => i.contentTypeCd == 'PROMO_POSTER_IMG')[0] ? data.dataResourceModels.filter(i => i.contentTypeCd == 'PROMO_POSTER_IMG')[0].objectInfo : ''
        // 存活动详情id
        sessionStorage.setItem('ActivityDetailsID', data.id);
        sessionStorage.setItem('fromDate', fromDate);
        sessionStorage.setItem('thurDate', thurDate);
        sessionStorage.setItem('posterImg', posterImg);
        console.log(data)
        sessionStorage.setItem('userLevelLimit', data.userLevelLimit);
        // 内容
        this.props.history.push('/ActivityWarp/ActivitDetails');
    };
    //选择活动 - M
    setCurType = (data) => {
        this.setActivityType(data.andGroupCd);
        this.setState({
            TypeName: data.andGroupName,
            TypeVisible: false
        });
    };
    //设置活动类型名字
    setTypeNAme = () => {
        this.setState({
            TypeVisible: true
        });
    };
    //点击更多按钮
    ActivityloadingMore = (n) => {
        let num = n + 6;
        this.setState({
            curCount: num
        });
        const { list } = this.state;
        if (num > list.length) {
            $('.ActivityBtn').hide();
        }
    };

    render() {
        const {
            list,
            testData,
            curCount,
            listType,
            TypeName,
            TypeVisible
        } = this.state;
        const routes = [
            {
                path: '/',
                breadcrumbName: '首页'
            },
            {
                path: '/ActivityWarp',
                breadcrumbName: '活动'
            }
        ];
        const content = (
            <div className="ActivityType-M">
                {
                    _.map(listType, (item, index) => {
                        return (
                            <div
                                key={index}
                                onClick={this.setCurType.bind(this, item)}
                            >{item.andGroupName}</div>
                        );
                    })
                }
            </div>
        );
        return (
            <div className="ActivityWarp">
                <div className="titleBar m-hied" style={{ padding: '0 13rem' }}>
                    <div className="site-page-header-ghost-wrapper">
                        <img src={HomeIcon} onClick={this.toHome} style={{ marginRight: 8 }} alt=""
                            className="Home-Icon" />
                        <PageHeader
                            ghost={false}
                            onClick={this.toHome}
                            breadcrumb={{ routes }}
                        >
                        </PageHeader>
                    </div>
                </div>
                <div className="Activity-wrap">
                    <div className="Activity-title">
                        <ul>
                            <li>
                                <h6>CGV活动</h6>
                                <span>(部分活动非全国性活动，详见APP)</span>
                            </li>
                            <li>
                                <span>活动类型：</span>
                                <div className="Activity-select-P">
                                    <Select
                                        defaultValue="全部活动"
                                        className="Activity-select"
                                        style={{
                                            marginRight: '10px'
                                        }}
                                        onChange={this.setActivityType}
                                    >
                                        {
                                            _.map(listType, (item, index) => {
                                                return (
                                                    <Option
                                                        value={item.andGroupCd}
                                                        key={index}
                                                    >
                                                        {item.andGroupName}
                                                    </Option>
                                                );
                                            })
                                        }
                                    </Select>
                                    <CaretDownOutlined className="P-icon-a" />
                                </div>
                                <div className="Activity-select-M">
                                    <span>
                                        活动类型：
                                    </span>
                                    <Popover
                                        placement="bottomRight"
                                        content={content}
                                        trigger="click"
                                        visible={TypeVisible}
                                    >
                                        <div onClick={this.setTypeNAme}>{TypeName}</div>
                                    </Popover>
                                    <CaretDownOutlined />
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="Activity-content">
                        {
                            _.slice(list, 0, curCount).map((item, index) => {
                                return (
                                    <dl key={index} onClick={this.toCurActive.bind(this, item)} style={{ position: 'relative' }}>
                                        { item.overdue ?
                                            <dt className="greyBg">
                                                <span>已过期</span>
                                            </dt> : null}
                                        <dt>
                                            {
                                                item.dataResourceModels.filter(s => s.contentTypeCd == 'PROMO_POSTER_MIN_IMG')[0] && item.dataResourceModels.filter(s => s.contentTypeCd == 'PROMO_POSTER_MIN_IMG')[0].objectInfo ?
                                                    <img
                                                        className="active-pic"
                                                        src={API_HOST_PRD_IMG + item.dataResourceModels.filter(s => s.contentTypeCd == 'PROMO_POSTER_MIN_IMG')[0].objectInfo} alt='' />
                                                    :
                                                    <img src={testData[0].img} alt='' className="active-pic" />
                                            }
                                        </dt>
                                        <dd>
                                            <p>
                                                <span>{item.promoName}</span>
                                                <span>
                                                    {
                                                        item.userLevelLimit ?
                                                            <div>
                                                                {
                                                                    item.userLevelLimit.split(',').filter(u => u == '01').length > 0 ?
                                                                        <em>
                                                                            <img src={Icon1} alt='' />
                                                                                初心
                                                                            </em> : null
                                                                }
                                                                {
                                                                    item.userLevelLimit.split(',').filter(u => u == '02').length > 0 ?
                                                                        <em>
                                                                            <img src={Icon2} alt="" />
                                                                         动心
                                                                         </em> : null
                                                                }
                                                                {
                                                                    item.userLevelLimit.split(',').filter(u => u == '03').length > 0 ?
                                                                        <em>
                                                                            <img src={Icon3} alt="" />
                                                                        知心</em> : null
                                                                }
                                                                {
                                                                    item.userLevelLimit.split(',').filter(u => u == '04').length > 0 ?
                                                                        <em>
                                                                            <img src={Icon4} alt="" />衷心</em> : null
                                                                }
                                                            </div>
                                                            :
                                                            <div>
                                                                <em>
                                                                    <img src={Icon1} alt='' />
                                                                            初心
                                                                        </em>
                                                                <em>
                                                                    <img src={Icon2} alt="" />
                                                                     动心
                                                                     </em>
                                                                <em>
                                                                    <img src={Icon3} alt="" />
                                                                    知心</em>
                                                                <em>
                                                                    <img src={Icon4} alt="" />
                                                                    衷心
                                                                    </em>
                                                            </div>
                                                    }
                                                </span>
                                            </p>
                                            <b>

                                                {
                                                    item.productPromoConds.map((fromdt, index) => {
                                                        if (fromdt.inputParam === 'FROM_DATE') {
                                                            {
                                                                return fromdt.condValue
                                                            }
                                                        }
                                                    })
                                                }
                                                {'~'}
                                                {
                                                    item.productPromoConds.map((thrudt, index) => {
                                                        if (thrudt.inputParam === 'THRU_DATE') {
                                                            {
                                                                return thrudt.condValue
                                                            }
                                                        }
                                                    })
                                                }


                                            </b>
                                        </dd>
                                    </dl>
                                );
                            })
                        }
                    </div>
                    {
                        list.length > 12 ?
                            <div className="moreBtn ActivityBtn"
                                onClick={this.ActivityloadingMore.bind(this, curCount)}>
                                <b>查看更多活动</b>
                                <i><img src={Downicon} alt="" /></i>
                            </div>
                            : ''
                    }
                </div>
            </div>
        );
    }
}

export default ActivityWarp;
