import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { withRouter } from 'react-router';
import MBG from '../../assets/images/dy-mr-bg.jpeg';
import { request, requestPost } from '../../utils/Axios';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import './TabsConpoent.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class jySlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    componentWillMount() {

    }

    // 路由跳转
    ToFilmWrapRP = (id) => {
        const { history } = this.props;
        history.push('/FilmWrap/FilmDetails');
        sessionStorage.setItem('FilmDetailsId', id);
        sessionStorage.setItem('FilmDetailsCode', '1');
    };

    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    render() {
        let settings = {};
        if (window.screen.width < 450) {
            settings = {
                dots: false,
                infinite: false,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 3,
                center: false
            };
        } else {
            settings = {
                dots: false,
                infinite: false,
                speed: 500,
                slidesToShow: 5,
                slidesToScroll: 5,
                center: false
            };
        }

        let DataR = this.props.data[0]
        let API_HOST_PRD_IMG = this.props.data[1]
        console.log(this.props.data)
        return (
            <div style={{ position: 'relative' }}>
                <Slider ref={c => (this.slider = c)} {...settings}>
                    {
                        _.map(DataR, (item, index) => {
                            return (
                                <div key={index}>
                                    <div className="dy-img-box">
                                        <div className="img-box">
                                            <div className="DY-img-show" onClick={this.ToFilmWrapRP.bind(this, item.id)}>
                                                {
                                                    item.smallImageUrl && item.smallImageUrl || item.smallImageUrl !== null ?
                                                        <img
                                                            src={API_HOST_PRD_IMG + item.smallImageUrl}
                                                            alt=""
                                                        />
                                                        : <img src={MBG} alt='' />
                                                }
                                            </div>
                                            <div className="DY-text-show">
                                                <div className="DY-texts-tips">
                                                    <p>{item.description}</p>
                                                </div>
                                                <div className="bottom-box">
                                                    <b onClick={this.ToFilmWrapRP.bind(this, item.id)}>查看详情</b>
                                                    <div className="DY-texts">
                                                        {item.likeCnt + '人想看'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dy-name">
                                            <div className="dy-name">{item.productName}</div>
                                            <div style={{ fontSize: ' 16px', color: ' #777', lineHeight: '16px', marginBottom: '10px' }}>
                                                {moment(item.releaseDate).format('YYYY-MM-DD')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>

                <span className="slider-btn left-slider-btn" onClick={this.previous}><LeftOutlined style={{ color: '#fff' }} /></span>
                <span className="slider-btn right-slider-btn" onClick={this.next}><RightOutlined style={{ color: '#fff' }} /></span>
            </div>
        );
    }
}

export default withRouter(jySlider);
