import React from "react";
import { Tabs } from 'antd';
import $ from 'jquery';
import MapBgL from '../../assets/images/map-bg1.png';
import MapBgR from '../../assets/images/map-bg2.png';
import './introduction.scss';

const { TabPane } = Tabs;

class Introduction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            size: 'small',
            LData: [],
            RData: []
        }
    }
    onChange = e => {
        this.setState({ size: e.target.value });
    };
    onChangeTabs = (activeKey) => {
        console.log(activeKey, '------------------------')
        if (activeKey === '2') {
            $('.ant-tabs-ink-bar').addClass('ant-tabs-ink-bars')
        } else {
            $('.ant-tabs-ink-bar').addClass('ant-tabs-ink-bars')

        }
    };
    componentDidMount() {
        $('.ant-tabs-ink-bar').addClass('ant-tabs-ink-bars')
    }
    render() {
        const { size } = this.state;
        return (
            <div className='TabsBox'>
                <h6>CGV影院简介</h6>
                <Tabs defaultActiveKey="1" animated={false} onChange={this.onChangeTabs} size={size}>
                    <TabPane tab="中国 TOP4 影投公司" key="1" >
                        <div className="icon-s icon-l" />
                        <dl>
                            <dt><img src={MapBgL} alt="" /></dt>
                            <dd className="text-content">
                                <div
                                    className="text-P text-M"
                                    style={{ textAlign: 'center' }}
                                >
                                    <em>2006年10月</em>
                                    开第一上海大宁店
                                    现已覆盖城市60+，影城100余家
                                    <br />单银幕产出第一
                                </div>
                                <div>
                                    2019年 <em>142个</em> SITES
                                </div>
                                <div>
                                    2020年 <em>162个</em> SITES
                                </div>
                            </dd>
                        </dl>
                    </TabPane>
                    <TabPane tab="Global TOP5 影投公司" key="2">
                        <div className="icon-s icon-r" />
                        <dl>
                            <dt><img src={MapBgR} alt="" /></dt>
                            <dd className="text-content">
                                <div
                                    className="textR-P textR-M"
                                >
                                    <span>已进入中国，越南，美国，印度，</span>
                                    <br />
                                    <span>
                                        印尼，缅甸，土耳其，俄罗斯<em>8</em>个国家
                                    </span>
                                </div>
                            </dd>
                        </dl>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
export default Introduction
