import React, { Component } from 'react';
import { Button, Popover, Radio } from 'antd';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import _ from 'lodash';
import QRCode from 'qrcode.react';
import { request } from '../../utils/Axios';
import AndiroidIcon from '../../assets/images/Android-cion.png';
import IOSIcon from '../../assets/images/pg-icon.png';
import EQpic from '../../assets/images/gzh.png';
import { withRouter } from 'react-router';
import './header.scss';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            a: true,
            b: false,
            c: false,
            d: false,
            e: false,
            AndiroidEWM: '',
        }
    }

    componentDidMount() {
        //生成按钮二维码链接
        request({
            url: '/api/version/02'
        })
            .then((res) => {
                if (res.code === 200) {
                    this.setState({
                        AndiroidEWM: res.data.filePath
                    })
                }
            })
        const urlPath = window.location.href;
        if (urlPath.indexOf('FilmWrap') !== -1) {
            $('.right-title ul li:nth-child(2)').addClass('curColor')
            this.setState({
                a: false,
                b: true,
                c: false,
                d: false,
                e: false,
            })
        }
        if (urlPath.indexOf('ActivityWarp') !== -1) {
            $('.right-title ul li:nth-child(3)').addClass('curColor')
            this.setState({
                a: false,
                b: false,
                c: true,
                d: false,
                e: false,
            })
        }
        if (urlPath.indexOf('StudiosWrap') !== -1) {
            $('.right-title ul li:nth-child(4)').addClass('curColor')
            this.setState({
                a: false,
                b: false,
                c: false,
                d: true,
                e: false,
            })
        }
        if (urlPath.indexOf('FriendWrap') !== -1) {
            $('.right-title ul li:nth-child(5)').addClass('curColor')
            this.setState({
                a: false,
                b: false,
                c: false,
                d: false,
                e: true,
            })
        }
        if (sessionStorage.getItem('curPage') === 'DY') {
            this.setState({
                a: false,
                b: true,
                c: false,
                d: false,
                e: false,
            })
        }
        if (sessionStorage.getItem('curPage') === 'YC') {
            this.setState({
                a: false,
                b: false,
                c: false,
                d: true,
                e: false,
            })
        }
    }


    toFilmWrap = (e) => {
        this.props.history.push('/FilmWrap', '电影');
        this.setState({
            a: false,
            b: true,
            c: false,
            d: false,
            e: false,
        })
    };
    toActivityWarp = () => {
        this.props.history.push('/ActivityWarp', '活动');
        this.setState({
            a: false,
            b: false,
            c: true,
            d: false,
            e: false,
        })
    };
    toStudiosWrap = () => {
        this.props.history.push('/StudiosWrap', '影城');
        this.setState({
            a: false,
            b: false,
            c: false,
            d: true,
            e: false,
        })
    };
    toFriendWrap = () => {
        this.props.history.push('/FriendWrap', 'PACONNIE朋友');
        this.setState({
            a: false,
            b: false,
            c: false,
            d: false,
            e: true,
        })
    };
    toHomePage = () => {
        this.props.history.push('/');
        this.setState({
            a: true,
            b: false,
            c: false,
            d: false,
            e: false,
        })
    }
    showMark = () => {
        $('.gzh-content').show();
        $('.android-content').hide();
        $('.Ios-content').hide();
    };
    showAndrold = () => {
        $('.gzh-content').hide();
        $('.android-content').show();
        $('.Ios-content').hide();
    };
    showIOS = () => {
        $('.gzh-content').hide();
        $('.android-content').hide();
        $('.Ios-content').show();
    };
    downAndrold = () => {
        var link = document.createElement('a');
        link.setAttribute("download", "");
        link.href = this.state.AndiroidEWM;
        link.click();
    };
    downIOS = () => {
        window.location.href = 'itms-apps://itunes.apple.com/cn/app/id687193022'
    };
    // 设置当前title状态
    curPages = (data) => {
        if (data.name === '首页') {
            _.map(this.state.list, (item) => {
                if (item.name === '首页') {
                    item.curActive = true
                    this.setState({
                        curActive: true
                    })
                }
            })
        } else {
            _.map(this.state.list, (item) => {
                item.curActive = false
            })
        }
        if (data.name === '电影') {
            _.map(this.state.list, (item) => {
                if (item.name === '电影') {
                    this.setState({
                    })
                    item.curActive = true
                }
            })
        } else {
            _.map(this.state.list, (item) => {
                item.curActive = false
            })
        }
        if (data.name === '活动') {
            _.map(this.state.list, (item) => {
                if (item.name === '活动') {
                    item.curActive = true
                }
            })
        } else {
            _.map(this.state.list, (item) => {
                item.curActive = false
            })
        }
        if (data.name === '影城') {
            _.map(this.state.list, (item) => {
                if (item.name === '影城') {
                    item.curActive = true
                }
            })
        } else {
            _.map(this.state.list, (item) => {
                item.curActive = false
            })
        }
        if (data.name === 'PACONNIE CLUB') {
            _.map(this.state.list, (item) => {
                if (item.name === 'PACONNIE CLUB') {
                    item.curActive = true
                }
            })
        } else {
            _.map(this.state.list, (item) => {
                item.curActive = false
            })
        }
    }
    render() {
        // FilmBanner 下载弹窗
        const content = (
            <div className="app-tips">
                <ol>
                    <li>下载APP或关注微信公众号</li>
                    <li>随时随地 掌上购票</li>
                    <hr />
                    <li>CGV电影购票</li>
                    <div className="Tips-P">
                        <ul>
                            <li>
                                <Button onClick={this.showAndrold}>
                                    <img src={AndiroidIcon} alt="" />
                                    Android下载
                                </Button>
                            </li>
                            <li>
                                <Button onClick={this.showIOS}>
                                    <img src={IOSIcon} alt="" />
                                    IOS下载
                                </Button>
                            </li>
                        </ul>
                        <hr />
                        <div className="EQ-content">
                            <dl className="gzh-content">
                                <dt>
                                    <em>
                                        <img src={EQpic} alt='' />
                                    </em>
                                </dt>
                                <dd>关注微信公众号</dd>
                            </dl>
                            <dl className="android-content">
                                <dt>
                                    <em>
                                        <QRCode value={this.state.AndiroidEWM} size={120} />
                                    </em>
                                </dt>
                                <dd>扫码下载android APP</dd>
                            </dl>
                            <dl className="Ios-content">
                                <dt>
                                    <em>
                                        <QRCode value={`https://apps.apple.com/cn/app/cgv%E7%94%B5%E5%BD%B1/id687193022`} size={120} />
                                    </em>
                                </dt>
                                <dd>扫码下载IOS APP</dd>
                            </dl>
                        </div>

                    </div>
                    <div className="Tips-M">
                        <ul>
                            <li>
                                <Button onClick={this.downAndrold}>
                                    <img src={AndiroidIcon} alt="" />
                                    Android下载
                                </Button>
                            </li>
                            <li>
                                <Button onClick={this.downIOS}>
                                    <img src={IOSIcon} alt="" />
                                    IOS下载
                                </Button>
                            </li>
                        </ul>
                        <hr />
                        <div>
                            <em>
                                <img src={EQpic} alt='' />
                            </em>
                            <i>关注微信公众号</i>
                        </div>
                    </div>
                </ol>
            </div>);
        return (
            <div className='header'>
                <div className='header-wrap'>
                    <div className="bannerMark" />
                    <div className='left-title'>
                        <h1>
                            <div onClick={this.toHomePage} className="left-img"></div>
                        </h1>
                        <div>
                            <Popover content={content} onClick={this.showMark}>
                                <div>
                                    <span /><span>CGV APP</span>
                                </div>
                            </Popover>
                        </div>
                    </div>
                    <div className='right-title'>
                        <ul>
                            <li className={this.state.a === true ? 'curActiveCss' : ''} onClick={this.toHomePage}>首页</li>
                            <li className={this.state.b === true ? 'curActiveCss' : ''} onClick={this.toFilmWrap}>电影</li>
                            <li className={this.state.c === true ? 'curActiveCss' : ''} onClick={this.toActivityWarp}>活动</li>
                            <li className={this.state.d === true ? 'curActiveCss' : ''} onClick={this.toStudiosWrap}>影城</li>
                            <li className={this.state.e === true ? 'curActiveCss' : ''} onClick={this.toFriendWrap}>PACONNIE CLUB</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Header);
