import React from 'react';
import { PageHeader, Tabs } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import $ from 'jquery';
import CXPic1 from '../../assets/images/djtq-cx-pic-iocn/dx-pic1.png';
import CXPic2 from '../../assets/images/djtq-cx-pic-iocn/dx-pic2.png';
import CXPic3 from '../../assets/images/djtq-cx-pic-iocn/dx-pic3.png';
import CXPic4 from '../../assets/images/djtq-cx-pic-iocn/dx-pic4.png';
import CXPic5 from '../../assets/images/djtq-cx-pic-iocn/dx-pic5.png';
import CXPic6 from '../../assets/images/djtq-cx-pic-iocn/dx-pic6.png';

import DXPic1 from '../../assets/images/djtq-dx-pic-iocn/dx-pic1.png';
import DXPic2 from '../../assets/images/djtq-dx-pic-iocn/dx-pic2.png';
import DXPic3 from '../../assets/images/djtq-dx-pic-iocn/dx-pic3.png';
import DXPic4 from '../../assets/images/djtq-dx-pic-iocn/dx-pic4.png';
import DXPic5 from '../../assets/images/djtq-dx-pic-iocn/dx-pic5.png';
import DXPic6 from '../../assets/images/djtq-dx-pic-iocn/dx-pic6.png';
import DXPic7 from '../../assets/images/djtq-dx-pic-iocn/dx-pic7.png';

import ZXPic1 from '../../assets/images/djtq-zx-pic-iocn/zx-pic1.png';
import ZXPic2 from '../../assets/images/djtq-zx-pic-iocn/zx-pic2.png';
import ZXPic3 from '../../assets/images/djtq-zx-pic-iocn/zx-pic3.png';
import ZXPic4 from '../../assets/images/djtq-zx-pic-iocn/zx-pic4.png';
import ZXPic5 from '../../assets/images/djtq-zx-pic-iocn/zx-pic5.png';
import ZXPic6 from '../../assets/images/djtq-zx-pic-iocn/zx-pic6.png';
import ZXPic7 from '../../assets/images/djtq-zx-pic-iocn/zx-pic7.png';

import XXPic1 from '../../assets/images/djtq-xx-pic-iocn/xx-pic1.png';
import XXPic2 from '../../assets/images/djtq-xx-pic-iocn/xx-pic2.png';
import XXPic3 from '../../assets/images/djtq-xx-pic-iocn/xx-pic3.png';
import XXPic4 from '../../assets/images/djtq-xx-pic-iocn/xx-pic4.png';
import XXPic5 from '../../assets/images/djtq-xx-pic-iocn/xx-pic5.png';
import XXPic6 from '../../assets/images/djtq-xx-pic-iocn/xx-pic6.png';
import XXPic7 from '../../assets/images/djtq-xx-pic-iocn/xx-pic7.png';
import XXPic8 from '../../assets/images/djtq-xx-pic-iocn/xx-pic8.png';
import PicContent from '../../assets/images/Paco-pic-bg.jpeg';
import Icon1 from '../../assets/images/pic-friend-icon.png';
import _ from 'lodash';
import './FriendWrap.scss';
import HomeIcon from '../../assets/images/home-icon.png';
import { request } from '../../utils/Axios';

const { TabPane } = Tabs;
let API_HOST_PRD_IMG = ''

class FriendWrap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            CX: [
                { img: CXPic1, title: '特价观影', text: '低至6折' },
                { img: CXPic2, title: '特价小食', text: '常年8.5折' },
                { img: CXPic3, title: '黄金座位', text: '每场最佳观影座位' },
                { img: CXPic4, title: '积分奖励', text: '指定消费金额的20%可积分' },
                { img: CXPic5, title: '升级礼遇', text: '送5积分' },
                { img: CXPic6, title: '限时退票', text: '开映2小时前可退' }
            ],
            DX: [
                { img: DXPic1, title: '特价观影', text: '低至6折' },
                { img: DXPic2, title: '特价小食', text: '常年8.5折' },
                { img: DXPic3, title: '黄金座位', text: '每场最佳观影座位' },
                { img: DXPic4, title: '积分奖励', text: '指定消费金额的20%可积分' },
                { img: DXPic5, title: '升级礼遇', text: '送19元观影券' },
                { img: DXPic6, title: '生日礼遇', text: '免费爆米花' },
                { img: DXPic7, title: '免费退票', text: '每年1次权限' }
            ],
            ZX: [
                { img: ZXPic1, title: '特价观影', text: '低至6折' },
                { img: ZXPic2, title: '特价小食', text: '常年8.5折' },
                { img: ZXPic3, title: '黄金座位', text: '每场最佳观影座位' },
                { img: ZXPic4, title: '积分奖励', text: '指定消费金额的22%可积分' },
                { img: ZXPic5, title: '升级礼遇', text: '送50积分' },
                { img: ZXPic6, title: '生日礼遇', text: '免费爆米花9元观影券' },
                { img: ZXPic7, title: '免费退票', text: '每月1次权限' }
            ],
            XX: [
                { img: XXPic1, title: '特价观影', text: '低至6折' },
                { img: XXPic2, title: '特价小食', text: '常年8.5折' },
                { img: XXPic3, title: '黄金座位', text: '每场最佳观影座位' },
                { img: XXPic4, title: '积分奖励', text: '指定消费金额的24%可积分' },
                { img: XXPic5, title: '升级礼遇', text: '送200积分' },
                { img: XXPic6, title: '生日礼遇', text: '免费爆米花 免费观影券' },
                { img: XXPic7, title: '年度礼遇', text: '全年免费爆米花 免费观影券1张（IMAX通用）' },
                { img: XXPic8, title: '免费退票', text: '每月1次权限' }
            ],
            cityCode: [],
            curCityCHNames: sessionStorage.getItem('curCityCHName'),
            picContent: '',
        };
    }

    componentDidMount() {
        request({
            url: '/party/api/getPropertyGetByPropertyId?resourceId=DOMAIN_IMG_SSL&propertyId=DOMAIN_IMG_SSL',
        })
            .then((res) => {
                API_HOST_PRD_IMG = res.systemPropertyValue
                this.getCityData();
                this.getList();
            });
    }
    getList = () => {
        request({
            url: '/content/api/homepageMaster/query?type=PACONNIECLUB&city='
        })
            .then((res) => {
                if (res.code === 200) {
                    if (res.data && res.data.length > 0) {
                        this.setState({
                            picContent: res.data[0]
                        })
                    }
                    console.log(this.state)
                }
            })
    }
    callback = (key) => {
        if (key === '1') {
            $(this).css({ color: 'skyblue' });
        } else if (key === '2') {
            $(this).addClass('buttonBorder');
        } else if (key === '3') {
            $('#tab-3').addClass('buttonBorder');
        } else if (key === '4') {
            $('#tab-4').addClass('buttonBorder');
        }
    };
    //titleBar
    getCityData = () => {
        request({
            url: '/product/areas/group',
            method: 'get'
        })
            .then((res) => {
                this.setState({
                    cityData: res
                });
            });
    };
    curCityCHNames = (city) => {
        this.setState({
            curCityCHNames: city.CITY_NAME
        });
        sessionStorage.setItem('curCityCHName', city.CITY_NAME);
    };
    toCity = () => {
        this.props.history.push('/McityWrap');
    };

    render() {
        const routes = [
            {
                path: '/',
                breadcrumbName: '首页'
            },
            {
                path: '/FriendWrap',
                breadcrumbName: 'PACONNIE CLUB'
            },
            {
                path: '/',
                breadcrumbName: 'PACONNIE的朋友',
            }
        ];
        let DOM = this.state.picContent.imageurl ? this.state.picContent.imageurl.split(',').map((item, index) => (<img src={item} alt='' key={index} />)) : ''
        return (
            <div className="FriendWrap">
                <div className="titleBar" style={{ padding: '0 13rem' }}>
                    <div className="site-page-header-ghost-wrapper">
                        <img
                            src={HomeIcon}
                            onClick={this.toHome}
                            alt=""
                            className="Home-Icon"
                            style={{ marginRight: '10px' }}
                        />
                        <PageHeader
                            ghost={false}
                            onClick={this.toHome}
                            breadcrumb={{ routes }}
                        >
                        </PageHeader>
                    </div>
                </div>
                <div className="FriendWrap-content">
                    <div className="FriendWrap-tabs">
                        <Tabs defaultActiveKey="1" animated={false}>
                            <TabPane tab="了解PACONNIE" key="1">
                                <div className="PACONNIE-tabs-content">
                                    {DOM}
                                </div>
                            </TabPane>
                            <TabPane tab="PACONNIE的朋友" key="2">
                                <div className="FriendWrap-tabs-content-bg">
                                    <div className="FriendWrap-tabs-content">
                                        <ul>
                                            <li>
                                                <p>
                                                    <img src={Icon1} alt='' />
                                                    成为PACONNIE的朋友
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    一、成为PACONNIE的朋友
                                                </p>
                                                <span>
                                                    您可在CGV影城官方APP、小程序或CGV影城现场柜台，
                                                    通过手机号码认证成功并注册后成为PACONNIE的朋友。
                                            </span>
                                            </li>
                                            <li>
                                                <p>
                                                    二、PACONNIE的朋友等级
                                                </p>
                                                <span>

                                                    CGV影城将根据您的活跃程度回馈您多重权益。PACONNIE的朋友共分为4个等级
                                                    ，从低到高分别为初心朋友(1级)，动心朋友(2级)，知心朋友(3级)，衷心朋友
                                                    (4级)。
                                                <br />
                                                您可通过CGV影城官方APP及小程序的个人页面查看当前朋友等级及权益详情
                                                （PACONNIE CLUB）。
                                                <br />
                                                    <div style={{ marginTop: 20 }} />
                                                1、朋友等级标准（等级级别由亲密值决定，共4个级别，满足条件后次日升级）
                                                <br />
                                                1）初心朋友(1级)：成功注册即可成为1级。
                                                <br />
                                                2）动心朋友(2级)：0 <LeftOutlined /> 亲密值 <LeftOutlined /> 80。
                                                <br />
                                                3)  知心朋友(3级)：80 <LeftOutlined /> 亲密值  <LeftOutlined /> 240。
                                                <br />
                                                4)  衷心朋友(4级)：240 <LeftOutlined /> 亲密值。
                                                <br />
                                                </span>
                                                <span>
                                                    <div style={{ marginTop: 20 }} />
                                                2、朋友亲密值累积规则
                                                <br />
                                                1）通过CGV影城官方APP、小程序或CGV影城现场柜台（需提前认证朋友身份）
                                                购买影票或卖品时，消费金额中实际付费的部分20%可累积亲密值（例：消费
                                                100元累积20亲密值）。
                                                <br />
                                                2）在第三方渠道购买影票或卖品不累积亲密值；未进行朋友身份验证的消费不累
                                                积亲密值。
                                                <br />
                                                3）购买朋友卡（全优卡/储值卡等）的卡费、充值金额及礼包金额不累
                                                积亲密值（特殊营销活动除外，详见活动细则），使用朋友卡消费时，余额或现
                                                金支付的部分可累积亲密值。
                                                <br />
                                                4）购买及使用兑换券、礼品卡直接兑换影票或卖品时不累积亲密值，现金加
                                                价部分可累积亲密值。
                                                <br />
                                                5）参与其他亲密值奖励活动时，可获取相应的亲密值，详情见活动细则。
                                                <br />
                                                6）单日获取亲密值上限为100，且单日最多8张电影票可获取亲密值。
                                                <br />
                                                7）亲密值有效期至当年12月31日，次年1月1日起从零开始累积。
                                            </span>
                                                <span>
                                                    <div style={{ marginTop: 20 }} />
                                                3、朋友等级有效期及保级降级规则
                                                <br />
                                                1）当前朋友等级及权益的有效期至当年12月31日。
                                                <br />
                                                2）每年1月1日，CGV影城将根据前一自然年（1月1日至12月31日）
                                                内新累积的朋友亲密值进行朋友等级的重新判定。亲密值若达到等级标
                                                准则予以保级；若未达到等级标准，则自动降级至相应等级；重新判定
                                                后的朋友等级有效期同样至当年12月31日（例：小A 2019年共累积亲
                                                密值260，2020年共累积亲密值120，则2020年1月1日起判定为衷心朋友，
                                                2021年1月1日起判定为知心朋友）。
                                            </span>
                                            </li>
                                            <li>
                                                <p>三、积分规则</p>
                                                <span>
                                                    您有两种方式获取积分，通过消费获取积分（如：购买影票/卖品）以及
                                                    通过活动获取积分（如：参加积分活动/注册下载等）。
                                            </span>
                                                <p />
                                                <span>
                                                    <div style={{ marginTop: 20 }} />
                                                1、消费积分获取规则
                                                <br />
                                                1）通过CGV影城官方APP、小程序或CGV影城现场柜台（需提前
                                                认证朋友身份）购买影票或卖品时，消费金额中实际付费的部分2
                                                0%可获取积分（例：消费100元获取20积分）。
                                                <br />
                                                2）在第三方渠道购买影票或卖品不获取积分；未进行朋友身份验证
                                                的消费不获取积分。
                                                <br />
                                                3）购买朋友卡（全优卡/储值卡等）的卡费、充值金额及礼包金额不
                                                获取积分（特殊营销活动除外，详见活动细则），使用朋友卡消费时
                                                ，余额或现金支付的部分可获取积分。
                                                <br />
                                                4）购买及使用兑换券、礼品卡直接兑换影票或卖品时不获取积分，
                                                现金加价部分可获取积分。
                                                <br />
                                                5）单日获取积分上限为100积分，且单日最多8张电影票可获取积分。
                                                <br />
                                                    <div style={{ marginTop: 20 }} />
                                                2、活动积分获取规则
                                                <br />
                                                1）当您注册成功时，可获取5积分。
                                                <br />
                                                2）当您首次下载CGV影城官方APP时，可获取5积分。
                                                <br />
                                                3）当您的个人资料完善度达到100%时，可最多获取5积分奖励(仅
                                                限CGV官方APP以及小程序渠道)。
                                                <br />
                                                4）参与其他积分奖励活动时，可获取相应的积分，详情见活动细则。
                                                <br />
                                                    <div style={{ marginTop: 20 }} />
                                                3、积分使用方式
                                                <br />
                                                有效积分可在CGV影城官方APP、小程序以下相关页面使用：
                                                <br />
                                                1）购买影票时，“支付页面”的“票券优惠版块”内可使用积分进行立减券快
                                                捷兑换（兑换比例：10积分=1元），并实现支付金额直接抵扣。
                                                <br />
                                                2）线上积分商城内可进行更多票券及礼品兑换。
                                                <br />
                                                3）线上积分商城内可使用积分参与积分互动游戏，赢取缤纷好礼。
                                                <br />
                                                    <div style={{ marginTop: 20 }} />
                                                4、积分有效期规则
                                                <br />
                                                1） 消费积分自生效日起至次年12月31日为止，逾期失效。
                                                <br />
                                                （例：于2020年1月1日/6月25日/12月31日获取的积分，积
                                                分有效期均至2021年12月31日为止）
                                                <br />
                                                2） 活动积分有效期详见活动细则。
                                                <br />
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    注：以上政策适用于2020年1月1日-2020年12月31日。
                                            </span>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="privilege">
                                    <h6 style={{ marginTop: '30px' }}>等级特权</h6>
                                    <div className="privilege-content">
                                        <Tabs defaultActiveKey="1" animated={false} onChange={this.callback}>
                                            <TabPane tab={<span className="privilege-pic1"><i></i>初心</span>} key="1">
                                                <div className="privilege-tabs-content">
                                                    {
                                                        _.map(this.state.CX, (item, index) => {
                                                            return (
                                                                <dl key={index}>
                                                                    <dt>
                                                                        <img src={item.img} alt="" />
                                                                    </dt>
                                                                    <dd>
                                                                        <p>{item.title}</p>
                                                                        <span>{item.text}</span>
                                                                    </dd>
                                                                </dl>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </TabPane>
                                            <TabPane tab={<span className="privilege-pic2"><i></i>动心</span>} key="2">
                                                <div className="privilege-tabs-content">
                                                    {
                                                        _.map(this.state.DX, (item, index) => {
                                                            return (
                                                                <dl key={index}>
                                                                    <dt>
                                                                        <img src={item.img} alt="" />
                                                                    </dt>
                                                                    <dd>
                                                                        <p>{item.title}</p>
                                                                        <span>{item.text}</span>
                                                                    </dd>
                                                                </dl>
                                                            );
                                                        })
                                                    }
                                                </div>

                                            </TabPane>
                                            <TabPane tab={<span className="privilege-pic3"><i></i>知心</span>} key="3">
                                                <div className="privilege-tabs-content">
                                                    {
                                                        _.map(this.state.ZX, (item, index) => {
                                                            return (
                                                                <dl key={index}>
                                                                    <dt>
                                                                        <img src={item.img} alt="" />
                                                                    </dt>
                                                                    <dd>
                                                                        <p>{item.title}</p>
                                                                        <span>{item.text}</span>
                                                                    </dd>
                                                                </dl>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </TabPane>
                                            <TabPane tab={<span className="privilege-pic4"><i></i>衷心</span>} key="4">
                                                <div className="privilege-tabs-content">
                                                    {
                                                        _.map(this.state.XX, (item, index) => {
                                                            return (
                                                                <dl key={index}>
                                                                    <dt>
                                                                        <img src={item.img} alt="" />
                                                                    </dt>
                                                                    <dd>
                                                                        <p>{item.title}</p>
                                                                        <span>{item.text}</span>
                                                                    </dd>
                                                                </dl>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}

export default FriendWrap;
