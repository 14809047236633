import React from 'react';
import {PageHeader, Tabs} from 'antd';
import {Link} from 'react-router-dom';
import GZH from '../../assets/images/gzh.png';
import appPic from '../../assets/images/LXWM-pic/app-pic.png';
import appPicHk from '../../assets/images/LXWM-pic/app-pic-hk.png';
import appPicMco from '../../assets/images/LXWM-pic/app-pic-mco.png';
import gzhPic from '../../assets/images/LXWM-pic/gzh.png';
import hyPic from '../../assets/images/LXWM-pic/xing.jpg';
import {
    HomeOutlined,
    WeiboOutlined,
    UserOutlined,
    MailOutlined
} from '@ant-design/icons';
import './ContactWrap.scss';
import HomeIcon from '../../assets/images/home-icon.png';

const {TabPane} = Tabs;

class ContactWrap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const routes = [
            {
                path: '/',
                breadcrumbName: '首页',
            },
            {
                path: '/ContactWrap',
                breadcrumbName: '联系我们'
            },
        ];
        return (
            <div>
                <div className="titleBar" style={{padding: '0 13rem'}}>
                    <div className="site-page-header-ghost-wrapper">
                        <img src={HomeIcon} onClick={this.toHome} alt="" className="Home-Icon"/>
                        <PageHeader
                            ghost={false}
                            onClick={this.toHome}
                            breadcrumb={{ routes }}
                        >
                        </PageHeader>
                    </div>
                </div>
                <div className="ContactWrap">
                    <Tabs defaultActiveKey="4" animated={false}>
                        <TabPane tab={<div><Link to="/Company">公司介绍</Link></div>} key="1">
                        </TabPane>
                        <TabPane tab={<div><Link to="/RecruitmentWrap">发展成就</Link></div>} key="2">
                        </TabPane>
                        <TabPane tab={<div><Link to="/RecruitmentWrap">诚聘英才</Link></div>} key="3">
                        </TabPane>
                        <TabPane tab={<div><Link to="/ContactWrap">联系我们</Link></div>} key="4">
                        </TabPane>
                    </Tabs>
                    <div>
                        <div className="ContactWrap-content">
                            <dl>
                                <dd>
                                    <p>关注方式</p>
                                    <p>
                                        <span>
                                            <table>
                                                <tr>
                                                    <td>
                                                        <i><WeiboOutlined/></i>
                                                        <em>官方网站：</em>
                                                    </td>
                                                    <td>
                                                        <b>www.cgv.com.cn(中国大陆)</b>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <b>https://cgv.com.hk/zh/(中国香港)</b>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <b>https://cgv.com.mo/zh/(中国澳门)</b>
                                                    </td>
                                                </tr>
                                            </table>
                                        </span>
                                        
                                        <span>
                                            <i><HomeOutlined/></i>
                                            <em>官方微博：</em>
                                            <b>weibo.com/cjcgv</b>
                                        </span>

                                
                                    </p>

                                </dd>
                                <dt>
                                    <span>
                                        <img style={{width: '135px',height: '135px'}} src={appPic} alt=""/>
                                        <em  style={{marginTop: '4px'}}>官方APP</em>
                                    </span>
                                    <span>
                                        <img style={{width: '135px',height: '135px'}} src={appPicHk} alt=""/>
                                        <em  style={{marginTop: '4px'}}>中国香港APP</em>
                                    </span>
                                    <span>
                                        <img style={{width: '135px',height: '135px'}} src={appPicMco} alt=""/>
                                        <em  style={{marginTop: '4px'}}>中国澳门APP</em>
                                    </span>
                                    {/* <span>
                                        <img style={{width: '135px',height: '135px'}} src={gzhPic} alt=""/>
                                        <em  style={{marginTop: '4px'}}>官方微信公众号</em>
                                    </span> */}
                                </dt>
                            </dl>
                            <dl>
                                <dd>
                                    <p>全国商务合作</p>
                                    <p>
                                        <span>
                                            <table>
                                                <tr>
                                                    <td>
                                                        <i><UserOutlined/></i>
                                                        <em>联系方式：</em>
                                                    </td>
                                                    <td>
                                                        <b>中国大陆 400-718-5575 (早 9:30 ~ 22:30)</b>
                                                    </td>
                                                </tr>
                                                <tr><td></td><td><b>中国香港 852-28634100 (周-~周五 10:00 ~18:00)</b></td></tr>
                                                <tr><td></td><td><b>中国澳门 853-28839810 (周-~周五 10:00 ~18:00)</b></td></tr>
                                            </table>
                                        </span> 
                                    </p>
                                </dd>
                                <dd>
                                    <p></p>
                                        <span>
                                            <table>
                                                <tr>
                                                    <td>
                                                        <i><MailOutlined/></i>
                                                        <em>邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：</em>
                                                    </td>
                                                    <td><b>中国大陆 jing.li11@cj.net(广告) ch.sun@cj.net(市场)</b></td>
                                                </tr>
                                                <tr><td></td><td><b>中国香港 cgv.hkmo@cj.net</b></td></tr>
                                                <tr><td></td><td><b>中国澳门 cgv.mo@cj.net</b></td></tr>
                                            </table>
                                        </span>
                                </dd>
                            </dl>

                            <dl>
                                <dd>
                                    <p>联系方式</p>
                                    <p>
                                        <span>
                                            <table>
                                                <tr>
                                                    <td>
                                                        <i><MailOutlined/></i>
                                                        <em>邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：</em>
                                                    </td>
                                                    <td>
                                                        <b>中国大陆 cgvkefu@cj.net&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </b>
                                                    </td>
                                                </tr>
                                                <tr><td></td><td><b>中国香港 cgv.hkmo@cj.net</b></td></tr>
                                                <tr><td></td><td><b>中国澳门 cgv.mo@cj.net</b></td></tr>
                                            </table>
                                        </span> 
                                    </p>
                                </dd>
                                <dd>
                                    <p></p>
                                        <span>
                                            <table>
                                                <tr>
                                                    <td>
                                                        <i><UserOutlined/></i>
                                                        <em>客服热线：</em>
                                                    </td>
                                                    <td><b>中国大陆 400-718-5575 (早 9:30 ~晚 22:30)</b></td>
                                                </tr>
                                                <tr><td></td><td><b>中国香港 852-28634100 (周-~周五 10:00~18:00)</b></td></tr>
                                                <tr><td></td><td><b>中国澳门 853-28839810 (周-~周五 10:00~18:00)</b></td></tr>
                                            </table>
                                        </span>
                                </dd>
                            </dl>


                            {/* <dl>
                                <dd>
                                    <p>联系方式</p>
                                    <p>
                                        <span>
                                            <table>
                                                <tr>
                                                    <td>
                                                        <i><MailOutlined/></i>
                                                        <em>邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：</em>
                                                    </td>
                                                    <td><b>中国大陆cgvkefu@cj.net</b></td>
                                                </tr>
                                                <tr><td></td><td><b>中国香港cgv.hkmo@cj.net</b></td></tr>
                                                <tr><td></td><td><b>中国澳门cgv.mo@cj.net</b></td></tr>

                                            </table>
                                        </span> 
                                    </p>
                                </dd>
                                <dd>
                                    <p></p>
                                        <span>
                                            <table>
                                                <tr>
                                                    <td>
                                                        <i><UserOutlined/></i>
                                                        <em>客服热线：</em>
                                                    </td>
                                                    <td>
                                                        <b>中国大陆 400-718-5575 (早 9:30 ~晚 22:30)</b>
                                                    </td>
                                                </tr>
                                                <tr><td></td><td><b>中国香港 +852-28634100(周-~周五 10:00~18:00)</b></td></tr>
                                                <tr><td></td><td><b>中国澳门 +853-28839810(周-~周五 10:00 ~18:00)</b></td></tr>
                                            </table>
                                        </span>
                                </dd>
                            </dl> */}

                            


                                {/* <dt>
                                    <span>
                                        <img style={{width: '135px',height: '135px'}}    src={gzhPic} alt=""/>
                                        <em style={{marginTop: '8px'}}>在线客服</em>
                                    </span>
                                </dt> */}
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactWrap;
